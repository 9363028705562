/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import NoCampaigns from "../../../images/404page/watchListEmpty.png";
import { Avatar, Button } from "@mui/material";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import VisibilityIcon from "@mui/icons-material/Visibility";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import nFormatter from "../../../utils/nFormatter";

const columns = [
  { id: "Profile", label: "Profile", minWidth: 200 },
  { id: "Category", label: "Category", align: "left", minWidth: 100 },
  { id: "Followers", label: "Followers", align: "left", minWidth: 100 },
  { id: "ER", label: "ER", align: "left", minWidth: 100 },
  {
    id: "Action",
    label: "Action",
    minWidth: 170,
    align: "center",
  },
];

const FavInfluencersContainer = ({
  toggleDrawer,
  removeFavInfHandler,
  favInfluencers,
  mediaQuery,
  showDetail = false,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      {favInfluencers.length === 0 && (
        <div
          style={{
            marginTop: "5.5rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <img loading="lazy" src={NoCampaigns} alt="NoCampaigns" />
          <Button
            variant="contained"
            startIcon={<GroupAddIcon fontSize="small" />}
            style={{
              width: "max-content",
              textAlign: "center",
              marginTop: "2.5rem",
            }}
            sx={{ mr: 1, mt: 1.5 }}
            onClick={() => {
              navigate("/influencers");
              if (!showDetail) {
                toggleDrawer("right", false);
              }
            }}
          >
            Go To Influencers
          </Button>
        </div>
      )}
      {favInfluencers.length > 0 && !showDetail && (
        <div style={{ marginTop: "2rem", width: "100%" }}>
          {favInfluencers?.map((inf) => {
            const youtubeHandlename =
              inf?.influencer_social_media === "youtube" &&
              String(inf.influencer_handle_name).startsWith("@")
                ? inf.influencer_handle_name
                : "@" + inf.influencer_handle_name;
            return (
              <div
                key={inf?.influencer_id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "1rem 1.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Avatar
                    src={inf?.influencer_profile_link}
                    alt={inf?.influencer_handle_name.toUpperCase()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "15px",
                        flex: ".95",
                        fontSize: "1rem",
                      }}
                    >
                      {inf?.influencer_name}
                    </p>
                    <p
                      style={{
                        marginLeft: "15px",
                        flex: ".95",
                        color: "blue",
                        fontSize: ".8rem",
                      }}
                    >
                      {String(inf.influencer_handle_name).startsWith("@")
                        ? inf.influencer_handle_name
                        : "@" + inf.influencer_handle_name}
                    </p>
                  </div>
                </div>
                <div>
                  <Tooltip title="View Analytics">
                    <IconButton
                      sx={mediaQuery ? { ml: 1 } : { ml: 0.2 }}
                      onClick={() =>
                        navigate(`/influencers/${inf?.influencer_id}`)
                      }
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Social Media">
                    <IconButton
                      href={
                        inf?.influencer_social_media === "twitter"
                          ? `https://www.twitter.com/${inf.influencer_handle_name}/`
                          : inf?.influencer_social_media === "youtube"
                          ? `https://www.youtube.com/${youtubeHandlename}`
                          : `https://www.instagram.com/${inf.influencer_handle_name}/`
                      }
                      target="_blank"
                      rel="noopener"
                    >
                      {inf?.influencer_social_media === "twitter" ? (
                        <TwitterIcon color="info" />
                      ) : inf?.influencer_social_media === "youtube" ? (
                        <YouTubeIcon color="error" />
                      ) : (
                        <InstagramIcon style={{ color: "#AA336A" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ ml: 0.2 }}
                      onClick={() => removeFavInfHandler(inf)}
                    >
                      <HeartBrokenIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {favInfluencers.length > 0 && showDetail && (
        <Paper sx={{ width: "95%", overflow: "hidden", margin: "1.5rem 2rem" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {favInfluencers
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((inf) => {
                    const youtubeHandlename =
                      inf?.influencer_social_media === "youtube" &&
                      String(inf.influencer_handle_name).startsWith("@")
                        ? inf.influencer_handle_name
                        : "@" + inf.influencer_handle_name;
                    return (
                      <TableRow
                        key={inf?.influencer_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        // style={{ cursor: "pointer" }}
                      >
                        <TableCell
                          style={{ display: "flex", alignItems: "center" }}
                          component="th"
                          scope="row"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              flexDirection: "row",
                            }}
                          >
                            <Avatar
                              src={inf?.influencer_profile_link}
                              alt={inf?.influencer_handle_name.toUpperCase()}
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{
                                  marginLeft: "15px",
                                  flex: ".95",
                                  fontSize: "1rem",
                                }}
                              >
                                {inf?.influencer_name}
                              </p>
                              <p
                                style={{
                                  marginLeft: "15px",
                                  flex: ".95",
                                  color: "blue",
                                  fontSize: ".8rem",
                                }}
                              >
                                {String(inf.influencer_handle_name).startsWith(
                                  "@"
                                )
                                  ? inf.influencer_handle_name
                                  : "@" + inf.influencer_handle_name}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">{inf?.category}</TableCell>
                        <TableCell align="left">
                          {nFormatter(inf?.follower_count)}
                        </TableCell>
                        <TableCell align="left">
                          {Number(inf.engagement_score || 0).toFixed(2) + "%"}
                        </TableCell>
                        <TableCell
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center",
                          }}
                          align="center"
                        >
                          <Tooltip title="View Analytics">
                            <IconButton
                              sx={mediaQuery ? { ml: 1 } : { ml: 0.2 }}
                              onClick={() =>
                                navigate(`/influencers/${inf?.influencer_id}`)
                              }
                            >
                              <VisibilityIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View Social Media">
                            <IconButton
                              href={
                                inf?.influencer_social_media === "twitter"
                                  ? `https://www.twitter.com/${inf.influencer_handle_name}/`
                                  : inf?.influencer_social_media === "youtube"
                                  ? `https://www.youtube.com/${youtubeHandlename}`
                                  : `https://www.instagram.com/${inf.influencer_handle_name}/`
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              {inf?.influencer_social_media === "twitter" ? (
                                <TwitterIcon color="info" />
                              ) : inf?.influencer_social_media === "youtube" ? (
                                <YouTubeIcon color="error" />
                              ) : (
                                <InstagramIcon style={{ color: "#AA336A" }} />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              sx={mediaQuery ? { ml: 1 } : { ml: 0.2 }}
                              onClick={() => removeFavInfHandler(inf)}
                            >
                              <HeartBrokenIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={favInfluencers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Fragment>
  );
};

export default FavInfluencersContainer;
