/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import InfluencersPosts from "../../campaigns/CampaignDetailPage/InfluencersPosts";
import timeAgo from "../../../utils/DateConverter";
import { CardHeader } from "@mui/material";

export default function AllPostsDrawer({ influencer }) {
  const [records, setRecords] = React.useState(12);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const list = () => (
    <Box sx={{ width: "75vw" }} role="presentation">
      <CardHeader
        title={`All ${influencer?.paid_partnership?.length} Paid Promotions of ${influencer?.handle}`}
        sx={{ padding: "1rem" }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          width: "100%",
          margin: "0 auto",
          gridGap: ".85rem",
          padding: "1rem 1rem",
        }}
      >
        {influencer?.paid_partnership?.slice(0, records)?.map((post, idx) => {
          return (
            <InfluencersPosts
              image={influencer?.profile_link}
              key={idx}
              user={influencer?.fullname || influencer?.handle}
              title={post.title}
              likes={post.likes}
              socialMedia={influencer?.social_media}
              comments={post.comments}
              views={post.views}
              shares={post.shares || post.retweet_count}
              desc={post.post || post.tweet}
              createdAt={timeAgo(post?.created_at)}
              hashtags={post?.post?.split(" ").filter((h) => h.includes("#"))}
              link={post?.link}
            />
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {records !== influencer?.paid_partnership?.length && (
          <Button variant="contained" sx={{ mb: 2 }} onClick={() => setRecords((prev) => prev + 12)}>
            Load More
          </Button>
        )}
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button variant="outlined" onClick={toggleDrawer(anchor, true)}>
            View All {influencer?.paid_partnership?.length} Posts
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
