/* eslint-disable */
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";

const states = [
  {
    value: "influencer",
    label: "Influencer",
  },
  {
    value: "business-personal",
    label: "Business Personal",
  },
];

export const AddUser = (props) => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    category: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div>
      <form {...props}>
        <Card>
          <CardHeader subheader="You can add Users" title="Add User" />
          <Divider />
          <CardContent>
            <Grid container  spacing={3}>
              <Grid item  md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the Full name"
                  label="Full name"
                  name="fullName"
                  onChange={handleChange}
                  required
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Category"
                  name="category"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.category}
                  variant="outlined"
                >
                  {states.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button color="primary" variant="contained">
              Add details
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};
