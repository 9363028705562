import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";

export const getAllSubscriptionPlans = createAsyncThunk(
  "subscription/getAllSubscriptionPlans",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}subscription/subscription_plan/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserSubscriptionHistory = createAsyncThunk(
  "subscription/getUserSubscriptionHistory",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}subscription/recharge_history/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const RequestSubscriptionPlan = createAsyncThunk(
  "subscription/RequestSubscriptionPlan",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}subscription/custom_plan/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const RechargeStripe = createAsyncThunk(
  "subscription/RechargeStripe",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}subscription/recharge_stripe/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTransactionHistory = createAsyncThunk(
  "subscription/getTransactionHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}subscription/checkout`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const ApplyCoupan = createAsyncThunk(
  "subscription/ApplyCoupan",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}subscription/addcoupon/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubscriptionStatus = createAsyncThunk(
  "subscription/getSubscriptionStatus",
  async (payload, {rejectWithValue, fulfillWithValue}) => {
    try {
      const res = await axios.get(
        `${BaseURL}subscription/reports_status/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
)

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscription_history: [],
    subscription_plans: [],
    unaccessable_report: false,
    loading: false,
    sending_mail: false,
    report: {},
    transactions: [],
    t_loading: false,
    statusHistory: {}
  },
  extraReducers: {
    [getAllSubscriptionPlans.pending]: (state) => {
      state.loading = true;
    },
    [getAllSubscriptionPlans.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscription_plans = action.payload;
    },
    [getAllSubscriptionPlans.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getUserSubscriptionHistory.pending]: (state) => {
      state.loading = true;
    },
    [getUserSubscriptionHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscription_history = action.payload;
    },
    [getUserSubscriptionHistory.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [RequestSubscriptionPlan.pending]: (state) => {
      state.sending_mail = true;
    },
    [RequestSubscriptionPlan.fulfilled]: (state) => {
      state.sending_mail = false;
    },
    [RequestSubscriptionPlan.rejected]: (state, action) => {
      state.sending_mail = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getTransactionHistory.pending]: (state) => {
      state.t_loading = true;
    },
    [getTransactionHistory.fulfilled]: (state, action) => {
      state.t_loading = false;
      state.transactions = action.payload;
    },
    [getTransactionHistory.rejected]: (state, action) => {
      state.t_loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getSubscriptionStatus.pending]: (state) => {
      state.t_loading = true;
    },
    [getSubscriptionStatus.fulfilled]: (state, action) => {
      state.t_loading = false;
      state.statusHistory = action.payload;
    },
    [getSubscriptionStatus.rejected]: (state, action) => {
      state.t_loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export default SubscriptionSlice.reducer;
