import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toastOptions } from "../../utils/toastOptions";
import { toast } from "react-toastify";

export const getCompetitorReport = createAsyncThunk(
  "competitor/getCompetitorReport",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      let res = null
      if(parseInt(payload.id) === 1 ) {
        res = await axios.get(`${BaseURL}common/base_report/?report_type=competitors`);
      } else {
        res = await axios.get(`${BaseURL}influencer/competitorreport/${payload.id}`);
      }
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const CompetitorSlice = createSlice({
  name: "competitor",
  initialState: {
    dummyCompetitorReport: {},
    singleCompetitorDetail: {},
    loading: false,
    error: initialError
  },
  reducers: {
    removeAlert(state) {
      state.error = initialError;
    },
  },
  extraReducers: {
    [getCompetitorReport.pending]: (state) => {
      state.loading = true;
    },
    [getCompetitorReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleCompetitorDetail = action.payload;
    },
    [getCompetitorReport.rejected]: (state, action) => {
      state.loading = false;
      state.singleCompetitorDetail = null;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export const CompetitorActions = CompetitorSlice.actions;

export default CompetitorSlice.reducer;
