/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircleIcon from "@mui/icons-material/Circle";
import timeAgo from "../../utils/DateConverter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TransactionTable({ transactions }) {
  return (
    <TableContainer
      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
      component={Paper}
    >
      <Table sx={{ width: "90vw" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Name</StyledTableCell>
            <StyledTableCell align="center">Plan</StyledTableCell>
            <StyledTableCell align="center">Address</StyledTableCell>
            <StyledTableCell align="center">GST No.</StyledTableCell>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
            <StyledTableCell align="center">Payment (₹)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.fullName}
              </StyledTableCell>
              <StyledTableCell align="center">{row.plan_title}</StyledTableCell>
              <StyledTableCell align="center">{row.address_1}</StyledTableCell>
              <StyledTableCell align="center">{row.gst_no}</StyledTableCell>
              <StyledTableCell align="center">{timeAgo(row.created_at)}</StyledTableCell>
              <StyledTableCell align="center" sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                {row.payment_successful ? (
                  <CircleIcon
                    sx={{ fontSize: "15px", mr: 1 }}
                    fontSize={"small"}
                    color="success"
                  />
                ) : (
                  <CircleIcon
                    sx={{ fontSize: "15px", mr: 1 }}
                    fontSize={"small"}
                    color="error"
                  />
                )}
                {row.payment_successful ? "Success" : "Failed"}
              </StyledTableCell>
              <StyledTableCell align="center">{row.recharge_amt + +row.recharge_amt * (18 / 100)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
