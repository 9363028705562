// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC2i1f4YYwVNZZgHN1OSecGYHnaiXdrMLY",
  authDomain: "pride-footprynt.firebaseapp.com",
  projectId: "pride-footprynt",
  storageBucket: "pride-footprynt.appspot.com",
  messagingSenderId: "101808021604",
  appId: "1:101808021604:web:60fa2ea4430127f7f0ff71",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
export const GoogleProvider = new GoogleAuthProvider();
export const FacebookProvider = new FacebookAuthProvider();
export const TwitterProvider = new TwitterAuthProvider();