/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
    Card,
    Title,
    TabList,
    Tab,
    TabGroup,
    TabPanel,
    TabPanels,
    AreaChart,
  } from "@tremor/react";
  
  import React, { useState } from "react";
  
  export default function FollowersAreaChart({ data }) {
    const [selectedIndex, setSelectedIndex] = useState("1M");
  
    return (
      <Card>
        <Title>Followers</Title>
        <TabGroup
          index={selectedIndex}
          onIndexChange={setSelectedIndex}
          className="mt-3"
        >
          <TabList variant="line">
            <Tab>1M</Tab>
            <Tab>2M</Tab>
            <Tab>3M</Tab>
            <Tab>6M</Tab>
            <Tab>1Y</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AreaChart
                className="h-80 mt-8"
                data={data?.slice(0, 30)?.map(ele => ({
                  ...ele,
                  date: ele.date ? ele?.date?.split("-")[1]+ "/" + ele?.date?.split("-")[2] : "N/A"
                }))}
                index="date"
                categories={["followers"]}
                colors={["blue"]}
                showLegend={false}
                yAxisWidth={48}
              />
            </TabPanel>
            <TabPanel>
              <AreaChart
                className="h-80 mt-8"
                data={data?.slice(0, 60)?.map(ele => ({
                  ...ele,
                  date: ele.date ? ele?.date?.split("-")[1]+ "/" + ele?.date?.split("-")[2] : "N/A"
                }))}
                index="date"
                categories={["followers"]}
                colors={["blue"]}
                showLegend={false}
                yAxisWidth={48}
              />
            </TabPanel>
            <TabPanel>
              <AreaChart
                className="h-80 mt-8"
                data={data?.slice(0, 90)?.map(ele => ({
                  ...ele,
                  date: ele.date ? ele?.date?.split("-")[1]+ "/" + ele?.date?.split("-")[2] : "N/A"
                }))}
                index="date"
                categories={["followers"]}
                colors={["blue"]}
                showLegend={false}
                yAxisWidth={48}
              />
            </TabPanel>
            <TabPanel>
              <AreaChart
                className="h-80 mt-8"
                data={data?.slice(0, 180)?.map(ele => ({
                  ...ele,
                  date: ele.date ? ele?.date?.split("-")[1]+ "/" + ele?.date?.split("-")[2] : "N/A"
                }))}
                index="date"
                categories={["followers"]}
                colors={["blue"]}
                showLegend={false}
                yAxisWidth={48}
              />
            </TabPanel>
            <TabPanel>
              <AreaChart
                className="h-80 mt-8"
                data={data?.map(ele => ({
                  ...ele,
                  date: ele.date ? ele?.date?.split("-")[1]+ "/" + ele?.date?.split("-")[2] : "N/A"
                }))}
                index="date"
                categories={["followers"]}
                colors={["blue"]}
                showLegend={false}
                yAxisWidth={48}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Card>
    );
  }
  