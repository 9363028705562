/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

//this slice is used to manage the state of all Filters globally

const initialValue = {
  country: "",
  category: [],
  activeBtn: "all",
  socialMedia: "all",
  page: 1,
  record: 10,
  lower_limit_followers: 1,
  upper_limit_followers: "10M+",
  gender: "",
  topSocialMediaBtns: "all",
};

const influencerStateSlice = createSlice({
  name: "influencerState",
  initialState: initialValue,
  reducers: {
    setCategory(state, action) {
      state.category = action.payload;
    },
    setActiveBtn(state, action) {
      state.activeBtn = action.payload;
    },
    setSoicalMedia(state, action) {
      state.socialMedia = action.payload;
    },
    setTopSocialMediaBtns(state, action) {
      state.topSocialMediaBtns = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setRecords(state, action) {
      state.record = action.payload;
    },
    setGender(state, action) {
      state.gender = action.payload;
    },
    setCountry(state, action) {
      state.country  = action.payload;
    },
    setLowerLimitFollowers(state, action) {
      state.lower_limit_followers  = action.payload;
    },
    setUpperLimitFollowers(state, action) {
      state.upper_limit_followers  = action.payload;
    },
  },
});

export const influencerStateActions = influencerStateSlice.actions;

export default influencerStateSlice.reducer;
