/* eslint-disable react/prop-types */
import * as React from "react";
import ChatIcon from "@mui/icons-material/Chat";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  red,
  blue,
  pink,
  yellow,
  green,
  purple,
  brown,
} from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReplyIcon from "@mui/icons-material/Reply";
import ExploreIcon from "@mui/icons-material/Explore";
import LyricsIcon from "@mui/icons-material/Lyrics";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Button, Tooltip } from "@mui/material";
import ScoreIcon from '@mui/icons-material/Score';
import nFormatter from "../../../utils/nFormatter";

export default function InfluencersPosts({
  user,
  socialMedia,
  desc,
  likes,
  shares,
  comments,
  bookmarks,
  replies,
  qoutes,
  impressions,
  hashtags,
  image,
  views,
  createdAt,
  title,
  link,
  style,
  score
}) {
  //console.log(views);
  const colors = [
    red[500],
    blue[500],
    pink[500],
    yellow[900],
    green[500],
    purple[500],
    brown[500],
  ];
  var index = desc?.lastIndexOf(" ");
  const descWithoutLink = desc?.substring(0, index);
  return (
    <Card
      sx={{ border: "0.25px solid rgb(201, 195, 195)" }}
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "220px",
        ...style,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: colors[Math.ceil(Math.random() * 7)] }}
            aria-label="recipe"
            src={image}
            alt={user}
          >
            {user?.slice(0, 1) || "Anonymous"}
          </Avatar>
        }
        title={user}
        subheader={createdAt}
        sx={{ pb: 0, mb: 0 }}
      />
      <CardContent
        sx={{
          mb: 0.5,
          pb: 0,
          mt: 2,
          pt: 0,
          flex: 1,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        {title && title?.length > 0 && (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "1.02rem",
              marginBottom: ".5rem",
            }}
          >
            {title}
          </Typography>
        )}
        <Typography variant="body2" color="primary">
          {hashtags?.map((tags) => tags + " ")}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {descWithoutLink?.length > 220
            ? descWithoutLink
                ?.trim()
                ?.split(" ")
                ?.filter((s) => s.includes("#") === false)
                .join(" ")
                .slice(0, 220) + "....read more"
            : descWithoutLink
                ?.trim()
                ?.split(" ")
                ?.filter((s) => s.includes("#") === false)
                .join(" ")}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
        disableSpacing
      >
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          {likes > 0 && (
            <Tooltip title="likes">
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(likes)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {shares > 0 && (
            <Tooltip title="Share">
              <IconButton aria-label="share">
                <ShareIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(shares)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {comments > 0 && (
            <Tooltip title="Comments">
              <IconButton aria-label="Comments">
                <ChatIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(comments)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {replies > 0 && (
            <Tooltip title="Replies">
              <IconButton aria-label="Replies">
                <ReplyIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(replies)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {qoutes > 0 && (
            <Tooltip title="qoutes">
              <IconButton aria-label="qoutes">
                <LyricsIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(qoutes)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {impressions > 0 && (
            <Tooltip title="impressions">
              <IconButton aria-label="impressions">
                <ExploreIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(impressions)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {bookmarks > 0 && (
            <Tooltip title="bookmarks">
              <IconButton aria-label="bookmarks">
                <BookmarkIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(bookmarks)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {views > 0 && (
            <Tooltip title="views">
              <IconButton aria-label="views">
                <RemoveRedEyeIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(views || 0)}
                </span>
              </IconButton>
            </Tooltip>
          )}
          {score > 0 && (
            <Tooltip title="score">
              <IconButton aria-label="score">
                <ScoreIcon />{" "}
                <span style={{ fontSize: ".85rem", marginLeft: "3px" }}>
                  {nFormatter(score || 0)}
                </span>
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Button
          variant="contained"
          disabled={!link && !desc?.trim()?.split(" ")?.pop().includes("https")}
          href={
            socialMedia === "instagram"
              ? String(link).startsWith("https://")
                ? link
                : "https://" + link
              : socialMedia === "youtube"
              ? String(link).startsWith("https://")
                ? link
                : "https://" + link
              : socialMedia === "twitter"
              ? desc?.trim()?.split(" ")?.pop()
              : link
          }
          target="_blank"
          rel="noopener noreferrer"
          size="small"
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
}
