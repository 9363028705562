import PerfectScrollbar from "react-perfect-scrollbar";
/* eslint-disable */
import {
  Avatar,
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  SvgIcon,
  Tooltip,
  IconButton
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { SeverityPill } from "../../home/severity-pill";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteUser, getAllUsers } from "../../../context/slice/userSlice";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "../../../styles/admin/LatestUsers.css";
import { getInitials } from "../../../utils/getInitials";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export const LatestUsers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((store) => store.users);
  useEffect(() => {
    dispatch(getAllUsers("users"));
  }, [dispatch]);
  const deleteUserHandler = async (id) => {
    await dispatch(deleteUser(id));
    await dispatch(getAllUsers("get"));
  };
  return (
    <>
      <div className="latestUsersHeader">
        <h2>Latest User's Accounts</h2>
      </div>
      <div className="widgets" >
        <div className="widget" style={{ backgroundColor: 'white', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 1px 2px' }}>
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              height: 56,
              width: 56,
              borderRadius: "15px",
            }}
            variant="square"
          >
            <GroupsIcon />
          </Avatar>
          <div className="user__widget-info">
            <div className="user_widget-info_title">Total Users</div>
            <div className="user_widget-info_value">2,420</div>
          </div>
          <div className="user__widget-profit success">
            <ArrowUpwardIcon />
            17.2%
          </div>
        </div>
        <div className="widget" style={{ backgroundColor: 'white', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 1px 2px' }}>
          <Avatar
            sx={{
              backgroundColor: "primary.light",
              height: 56,
              width: 56,
              borderRadius: "15px",
            }}
            variant="square"
          >
            <PeopleIcon />
          </Avatar>
          <div className="user__widget-info">
            <div className="user_widget-info_title">Active Users</div>
            <div className="user_widget-info_value">1,897</div>
          </div>
          <div className="user__widget-profit success">
            <ArrowUpwardIcon />
            32.7%
          </div>
        </div>
        <div className="widget" style={{ backgroundColor: 'white', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 1px 2px' }}>
          <Avatar
            variant="square"
            sx={{
              backgroundColor: "success.main",
              height: 56,
              borderRadius: "15px",

              width: 56,
            }}
          >
            <PersonAddAltIcon />
          </Avatar>
          <div className="user__widget-info">
            <div className="user_widget-info_title">New Users</div>
            <div className="user_widget-info_value">241</div>
          </div>
          <div className="user__widget-profit fail">
            <ArrowDownwardIcon />
            -3.2%
          </div>
        </div>
      </div>
      <Card {...props}>

        <PerfectScrollbar>
          <Box sx={{ minWidth: 800 }}><br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, 5).map((user) => (
                  <TableRow key={user.id}>
                    <TableCell
                      style={{
                        alignItems: "center",
                        display: "flex",
                        maxWidth: "130px",
                      }}
                    >
                      <Avatar src={user.fullName} sx={{ mr: 2 }}>
                        {getInitials(user.fullName)}
                      </Avatar>
                      {user.fullName}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>
                      <h4 style={{ color: user?.is_active ? "success" : "error" }} >
                        {user?.is_active ? "Active" : "Inactive"}
                      </h4>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton>
                          <EditIcon
                            onClick={() => navigate(`/admin-panel/users/${user.id}`)}
                            style={{ color: "#42855B", cursor: "pointer" }}
                            sx={{
                              "&:hover": {
                                // boxShadow: "0px 0px 11px -1px rgba(0,0,0,0.75)",
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton>
                          <DeleteIcon
                            onClick={() => deleteUserHandler(user.id)}
                            style={{
                              color: "#FF6464",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                            sx={{
                              "&:hover": {
                                // boxShadow: "0px 0px 11px -1px rgba(0,0,0,0.75)",
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>


                      {/* <RemoveRedEyeIcon style={{ color: "blue", marginLeft: "5px"}}/> */}
                    </TableCell>
                  </TableRow>


                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon fontSize="small" />}
            size="small"
            variant="text"
            onClick={() => navigate("/admin-panel/users")}
          >
            View all
          </Button>
        </Box>
      </Card>
    </>
  );
};