/* eslint-disable react/prop-types */
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";

export const CompareCompetitorsContext = createContext({
  competitorsList: [],
  addCompetitorsToCart: () => {},
  removeOneCompetitorFromCart: () => {},
});

export function CompareCompetitorsProvider({ children }) {
  const [cartProducts, setCartProducts] = useState([]);

  function addCompetitorsToCart(item) {
    const isExist = cartProducts.find(
      (inf) => inf.id === item.id
    );
    if (isExist) {
      return toast.error("Competitor has already been added", toastOptions);
    }
    setCartProducts([...cartProducts, item]);
  }
  function removeOneCompetitorFromCart(id) {
    if (cartProducts.length === 0) {
      return toast.error(
        "Cannot remove Influencers!! Since the cart is empty",
        toastOptions
      );
    }
    setCartProducts(
      cartProducts.filter((product) => product.id !== id)
    );
  }

  const contextValue = {
    competitorsList: cartProducts,
    addCompetitorsToCart,
    removeOneCompetitorFromCart,
  };
  return (
    <CompareCompetitorsContext.Provider value={contextValue}>
      {children}
    </CompareCompetitorsContext.Provider>
  );
}
