import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/MuiTheme";
import Router from "./router/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global/HoverCardSidebar.css";
import React from "react";
import AppLoader from "./pages/loader";

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <AppLoader>
          <Router />
        </AppLoader>
      </ThemeProvider>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
