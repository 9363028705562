import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  //default
  prevRoute: {
    name: "influencers",
    url: "/influencers",
  },
};

const historySlice = createSlice({
  name: "history",
  initialState: initialValue,
  reducers: {
    setHistory(state, action) {
      state.prevRoute = { name: action.payload.name, url: action.payload.URL };
    },
  },
});

export const historyActions = historySlice.actions;

export default historySlice.reducer;
