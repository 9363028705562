/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircleIcon from "@mui/icons-material/Circle";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SubscriptionHistoryTable({ subscription }) {
  return (
    <TableContainer
      sx={{ display: "flex", justifyContent: "center", mt: 3 }}
      component={Paper}
    >
      <Table sx={{ width: "90vw" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Subscription</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Expires On</StyledTableCell>
            <StyledTableCell align="center">Credits</StyledTableCell>
            <StyledTableCell align="center">Payment (₹)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscription.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.Description?.substring(0,1)?.toUpperCase() + row.Description?.substring(1)}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center" }}>
                {row.active ? (
                  <CircleIcon
                    sx={{ fontSize: "15px", mr: 1 }}
                    fontSize={"small"}
                    color="success"
                  />
                ) : (
                  <CircleIcon
                    sx={{ fontSize: "15px", mr: 1 }}
                    fontSize={"small"}
                    color="error"
                  />
                )}
                {row.active ? "Active" : "InActive"}
              </StyledTableCell>
              <StyledTableCell align="center">{row.expires_on}</StyledTableCell>
              <StyledTableCell align="center">
                {row.credits_remaining}
              </StyledTableCell>
              <StyledTableCell align="center">{row.amount}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
