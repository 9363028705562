/* eslint-disable no-unused-vars */
import React, { Suspense, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { DashboardLayout } from "../layouts/DashboardLayout";
import AdminPanel from "../pages/admin/AdminPanel";
import { AddUser } from "../pages/admin/user/AddUser";
import AllUsers from "../pages/admin/user/AllUsers";
import UserDetails from "../pages/admin/user/UserDetails";
import { EditFlag } from "../pages/admin/flag/EditFlag";
import { AddFeatureFlag } from "../pages/admin/flag/AddFlag";
const AllRole = React.lazy(() => import("../pages/admin/role/AllRole"));
const AddRole = React.lazy(() => import("../pages/admin/role/AddRole"));
const AllFlag = React.lazy(() => import("../pages/admin/flag/AllFlag"));

const Login = React.lazy(() => import("../pages/auth/LoginPage"));
const Influencers = React.lazy(() => import("../pages/Influencers"));
const FeaturedInfluencers = React.lazy(() =>
  import("../pages/FeaturedInfluencers")
);
const InfluencerDetail = React.lazy(() =>
  import("../pages/influencers/detail/InfluencerDetail")
);
const Products = React.lazy(() => import("../pages/campaigns"));
const Business = React.lazy(() => import("../pages/dashbord/Business"));
const CampaignDetail = React.lazy(() =>
  import("../pages/campaign/CampaignDetail")
);
import Profile from "../pages/user/Profile";
const CompetitorAnalysis = React.lazy(() =>
  import("../pages/competitor/CompetitorAnalysis")
);
const Page404 = React.lazy(() => import("../pages/Page404"));

import { CompareCartContext } from "../context/slice/CompareInfluencerSlice";
import { CompareCompetitorsContext } from "../context/slice/CompareCompetitorsSlice";
import CompetitorDetailPg from "../pages/competitor/CompetitorDetailPg";
import HashtagDetail from "../pages/hashtag/HashtagDetail";
import Checkout from "../pages/payment/Checkout";
import PageLoader from "../pages/loader/PageLoader";
import Success from "../pages/payment/Success";
import PaymentRejected from "../pages/payment/Cancel";
const CompareCompetitors = React.lazy(() =>
  import("../pages/compare/CompareCompetitors")
);

const TopInfluencers = React.lazy(() => import("../pages/TopInfluencers"));
const CompareInfluencers = React.lazy(() =>
  import("../pages/compare/CompareInfluencers")
);

const Router = () => {
  const { user } = useSelector((store) => store.users);
  const naviagte = useNavigate()
  const ctx = useContext(CompareCartContext);
  const competitorCtx = useContext(CompareCompetitorsContext);


  

  return (
    <div>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route
            path={"/"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Business />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/topinfluencers"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <TopInfluencers />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/featured-influencers"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <FeaturedInfluencers />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/campaign"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Products />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/campaign/:id"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <CampaignDetail />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/competitor-analysis"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <CompetitorAnalysis />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/success"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Success />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/rejected"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <PaymentRejected />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/competitor-analysis/:id"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <CompetitorDetailPg />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/user-profile"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Profile />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/checkout"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Checkout />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/compare"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  {ctx.items.length > 0 ? (
                    <CompareInfluencers />
                  ) : (
                    <Navigate to={"/influencers"} replace />
                  )}
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/compare/competitors"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  {competitorCtx.competitorsList.length > 0 ? (
                    <CompareCompetitors />
                  ) : (
                    <Navigate to={"/competitor-analysis"} replace />
                  )}
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/influencers"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <Influencers />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/influencers/:influencerId"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <InfluencerDetail />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/hashtag/:hashtagId"}
            element={
              user?.token?.access ? (
                <DashboardLayout>
                  <HashtagDetail />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/latest-users"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AdminPanel />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/users"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AllUsers />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/flag"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AllFlag />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/flag/add-flag"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AddFeatureFlag />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/flag/:id"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <EditFlag />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/role"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AllRole />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/role/add-role"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AddRole />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/add-user"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <AddUser />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/admin-panel/users/:id"}
            element={
              user?.token?.access && user.user.is_admin ? (
                <DashboardLayout>
                  <UserDetails />
                </DashboardLayout>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/login"}
            element={
              !user?.token?.access ? <Login /> : <Navigate to={"/"} replace />
            }
          />
          <Route
            path={"*"}
            element={
              !user?.access ? (
                <Page404 />
              ) : (
                <DashboardLayout>
                  <Page404 />
                </DashboardLayout>
              )
            }
          />
        </Routes>
      </Suspense>
    </div>
  );
};

export default Router;
