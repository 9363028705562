/*eslint-disable*/
import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../utils/toastOptions";

export const axiosMiddleware = (store) => (next) => (action) => {
  const LoggedInUser = localStorage.getItem("footprynt_pride_key")
    ? JSON.parse(localStorage.getItem("footprynt_pride_key"))
    : null;

  // //console.log(store?.getState()?.users?.user);

  axios.interceptors.request.use((req) => {
    if (LoggedInUser) {
      if(req.url.includes("topinfluencers")) {
        return req 
      } else {
        const token = "Bearer " + LoggedInUser?.token?.access;
        req.headers.Authorization = token;
        return req;
      }
    } else {
      return req
    }
  });

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("footprynt_pride_key");
        window.location.replace(`/login`);
        window.location.reload()
        return;
      }
      return err;
    }
  );

  return next(action);
};
