/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const ApiHandleSlice = createSlice({
  name: "apihandle",
  initialState: {
    callUserAnaystics: true,
    callInfluencers: true,
    callCampaigns: true,
    callCompetitors: true,
    callProfilecomplete: true,
    callFavInfluencers: true,
    callNotifications: true,
    alertCampaignToCreate: true,
    callUserFeatureFlag: true
  },
  reducers: {
    startUserAnalytics: (state, action) => {
      state.callUserAnaystics = true
    },
    stopUserAnalytics: (state, action) => {
      state.callUserAnaystics = false
    },
    startInfluencerCall: (state, action) => {
      state.callInfluencers = true
    },
    stopInfluencerCall: (state, action) => {
      state.callInfluencers = false
    },
    startCampaignsCall: (state, action) => {
      state.callCampaigns = true
    },
    stopCampaignsCall: (state, action) => {
      state.callCampaigns = false
    },
    startCompetitorsCall: (state, action) => {
      state.callCompetitors = true
    },
    stopCompetitorsCall: (state, action) => {
      state.callCompetitors = false
    },
    startProfilecompleteCall: (state, action) => {
      state.callProfilecomplete = true
    },
    stopProfilecompleteCall: (state, action) => {
      state.callProfilecomplete = false
    },
    startFavInfluencersCall: (state, action) => {
      state.callFavInfluencers = true
    },
    stopFavInfluencersCall: (state, action) => {
      state.callFavInfluencers = false
    },
    startNotificationCall: (state, action) => {
      state.callNotifications = true
    },
    stopNotificationCall: (state, action) => {
      state.callNotifications = false
    },
    openCampaignModal: (state, action) => {
      state.alertCampaignToCreate = true
    },
    closeCampaignModal: (state, action) => {
      state.alertCampaignToCreate = false
    },
    startUserFeatureFlag: (state, action) => {
      state.callUserFeatureFlag = true
    },
    stopUserFeatureFlag: (state, action) => {
      state.callUserFeatureFlag = false
    },
  },
});

export const apihandleActions = ApiHandleSlice.actions;

export default ApiHandleSlice.reducer;
