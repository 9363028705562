/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { BaseURL } from "../../services/API_services";
import VerifiedIcon from "@mui/icons-material/Verified";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const VerifyLinkTextField = ({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  logic,
  valid,
  touched,
  setValid,
  ...props
}) => {
  const [debouncedValue] = useDebounce(value, 500);

  const LoggedInUser = localStorage.getItem("footprynt_pride_key")
    ? JSON.parse(localStorage.getItem("footprynt_pride_key"))
    : null;
  const token = "Bearer " + LoggedInUser?.token?.access;

  useEffect(() => {
    if (debouncedValue && logic === "VerifyLink") {
      let valid_url = "";
      if (value.includes("https://")) {
        valid_url = value;
      } else {
        valid_url = "https://" + value;
      }
      axios
        .post(
          `${BaseURL}common/verifyurl/?company_link=${valid_url}`,
          {},
          { headers: { Authorization: token } }
        )
        .then((res) => {
          if (res.status === 200 || res.statusText === "OK") {
            setValid("valid");
          } else {
            setValid("invalid");
          }
        });
    }
  }, [debouncedValue]);

  return (
    <div style={{ position: "relative" }}>
      <TextField
        {...props}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error || (valid === "invalid" && value.length > 0)}
      />
      {value.length > 0 && valid === "valid" && (
        <VerifiedIcon
          style={{
            color: "green",
            position: "absolute",
            left: "90%",
            top: "44%",
          }}
        />
      )}
      {value.length > 0 && valid === "invalid" && (
        <DoDisturbIcon
          style={{
            color: "red",
            position: "absolute",
            left: "90%",
            top: "44%",
          }}
        />
      )}
    </div>
  );
};

export default VerifyLinkTextField;
