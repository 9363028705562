/* eslint-disable */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";

export const getDashboardData = createAsyncThunk(
  "campaign/getDashboardData",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/dashboard/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSearchHistory = createAsyncThunk(
  "campaign/getSearchHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/inf_history/?type=search`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFilterHistory = createAsyncThunk(
  "campaign/getFilterHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/inf_history/?type=filter`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardData: {},
    loading: false,
    searchHistory: [],
    searchLoading: false,
    filterHistory: [],
    filterLoading: false,
    isError: false,
    errorMessage: ""
  },
  reducers: {
  },
  extraReducers: {
    // dashboardData
    [getDashboardData.pending]: (state, action) => {
      state.loading = true;
      state.isError = false
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
    },
    [getDashboardData.rejected]: (state, action) => {
      state.loading = false;
      state.isError = true
      state.errorMessage = action.payload.detail || action.payload.msg || "Server error";
    },
    // searchHistory
    [getSearchHistory.pending]: (state, action) => {
      state.searchLoading = true;
      state.isError = false
    },
    [getSearchHistory.fulfilled]: (state, action) => {
      state.searchLoading = false;
      state.searchHistory = action.payload;
    },
    [getSearchHistory.rejected]: (state, action) => {
      state.searchLoading = false;
      state.isError = true
      state.errorMessage = action.payload.detail || action.payload.msg || "Server error";
    },
    // filterHistory
    [getFilterHistory.pending]: (state, action) => {
      state.filterLoading = true;
      state.isError = false
    },
    [getFilterHistory.fulfilled]: (state, action) => {
      state.filterLoading = false;
      state.filterHistory = action.payload;
    },
    [getFilterHistory.rejected]: (state, action) => {
      state.filterLoading = false;
      state.isError = true
      state.errorMessage = action.payload.detail || action.payload.msg || "Server error";
    },
  },
});

export const DashboardActions = DashboardSlice.actions;

export default DashboardSlice.reducer;
