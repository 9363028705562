import { Box } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import MainLoader from "../../assets/MainLoader.gif";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router";
import { signOut } from "@firebase/auth";
import { firebaseAuth } from "../../firebase/firebase.config";

// eslint-disable-next-line react/prop-types
const AppLoader = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((store) => store.users);
  const navigate = useNavigate()
  useEffect(() => {
    (async function () {
      const accessToken = user?.token?.access;
      setLoading(true);
      if(!user){
        setLoading(false);
        return;
      }
      const decoded = jwtDecode(accessToken);
      console.log(decoded.exp, Date.now());
      if(Date.now() > decoded.exp) {
        setLoading(false);
      } else {
        await signOut(firebaseAuth)
        localStorage.removeItem("footprynt_pride_key");
        navigate("/login")
        setLoading(false);
      }
    })();
  }, [user]);
  return (
    <Fragment>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#2BBBFB",
            position: "fixed",
            top: "0%",
            left: "0%",
            zIndex: 99999999,
            overflowY: "hidden",
          }}
        >
          <img
            src={MainLoader}
            alt="MainLoader"
            style={{ objectFit: "cover" }}
          />
        </Box>
      ) : (
        children
      )}
    </Fragment>
  );
};

export default AppLoader;
