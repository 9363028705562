/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import { AccountPopover } from "./AccountPopUp";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import FavInfDrawer from "../influencers/favInfluencers/FavInfDrawer";
import UserImage from "../../images/auth/UserImage.jpeg";
import { getUserSubscriptionHistory } from "../../context/slice/SubscriptionSlice";
import nFormatter from "../../utils/nFormatter";
import BoltIcon from "@mui/icons-material/Bolt";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

// eslint-disable-next-line no-unused-vars
export const DashboardNavbar = ({ open, ...props }) => {
  const { setSidebarOpen, ...other } = props;
  const settingsRef = useRef(null);
  const dispatch = useDispatch();
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const { user, competitors  } = useSelector((store) => store.users);
  const MediaQuery = useMediaQuery("(min-width:950px)");

  const { subscription_history, loading } = useSelector(
    (store) => store.subscription
  );

  const { influencer, grpCreated, compare } = useSelector(
    (store) => store.influencers
  );
  useEffect(() => {
    dispatch(getUserSubscriptionHistory());
  }, [dispatch, grpCreated, influencer, competitors, compare]);

  return (
    <>
      <DashboardNavbarRoot
        sx={
          MediaQuery
            ? {
                left: 80,
                width: "calc(100% - 80PX)",
                zIndex: "9",
              }
            : { zIndex: "9" }
        }
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={() => setSidebarOpen((prev) => !prev)}
            sx={{
              display: {
                xs: "inline-flex",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Points">
            <Chip
              icon={<BoltIcon />}
              label={
                !loading
                  ? nFormatter(
                      subscription_history?.[0]?.credits_remaining || 0
                    )
                  : "Evaluating..."
              }
            />
          </Tooltip>
          <FavInfDrawer />
          <Avatar
            id="profile"
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
            sx={{
              cursor: "pointer",
              height: 40,
              width: 40,
              ml: 1,
            }}
            src={!user?.user?.dp ? UserImage : user?.user?.dp}
          >
            <UserCircleIcon fontSize="small" />
          </Avatar>
        </Toolbar>
      </DashboardNavbarRoot>
      <AccountPopover
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </>
  );
};
