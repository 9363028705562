import React from "react";
import PaymentRejectedGIF from "../../assets/rejected.gif";
import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';

const PaymentRejected = () => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
        bgcolor: "#EFF1F3",
        height: "90vh",
        padding: "2rem 0",
        overflowY: "hidden"
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              loading="lazy"
              alt="PaymentRejected"
              src={PaymentRejectedGIF}
              style={{
                marginTop: 10,
                display: "inline-block",
                maxWidth: "100%",
                width: 560,
              }}
            />
          </Box>
          <Typography align="center" color="textPrimary" variant="h3">
            Payment Rejected
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle1">
            Oops! Your payment didn&apos;t go through. Please check your payment
            details and try again, or contact our support team for assistance.
          </Typography>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Link to={"/"}>
              <Button
                component="a"
                startIcon={<ArrowBackIcon fontSize="small" />}
                sx={{ mt: 3 }}
                variant="contained"
              >
                Go back to dashboard
              </Button>
            </Link>
            <Link to={"/"}>
              <Button
                component="a"
                startIcon={<EmailIcon fontSize="small" />}
                sx={{ mt: 3, ml: 2 }}
                variant="contained"
                color="error"
              >
                Contact our Team
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentRejected;
