import React from "react";
import PaymentSuccessImage from "../../assets/success-2.gif";
import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from 'react-router-dom';

const Success = () => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
      style={{ margin: "4rem 0", marginTop: "1rem" }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <img
              loading="lazy"
              alt="PaymentSuccessImage"
              src={PaymentSuccessImage}
              style={{
                // marginTop: 10,
                display: "inline-block",
                maxWidth: "100%",
                width: 560,
              }}
            />
          </Box>
          <Typography align="center" color="textPrimary" variant="h3">
            Your Payment is Successfull
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle1">
            Thank you for your payment. An automated payment receipt will be
            sent to your registered email.
          </Typography>

          <Link to={"/"}>
            <Button
              component="a"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained"
            >
              Go back to dashboard
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Success;
