import { Button } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { useDispatch } from "react-redux";
import { historyActions } from "../../context/slice/HistorySlice";
import { CompareCompetitorsContext } from "../../context/slice/CompareCompetitorsSlice";

const CompareCompetitorButton = () => {
  const ctx = useContext(CompareCompetitorsContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function compareHandler() {
    if (ctx.competitorsList.length === 0) {
      return toast.warn(
        "Please select at least 1 Competitor to compare your analytics",
        toastOptions
      );
    }
    dispatch(
      historyActions.setHistory({
        URL: location.pathname,
        name: location.pathname,
      })
    );
    navigate("/compare/competitors");
  }
  return (
    <div
      style={{ position: "fixed", bottom: "5%", right: "2.5%", zIndex: "100", display: ctx.competitorsList.length === 0 ? "none" : "initial" }}
    >
      <Button onClick={compareHandler} variant="contained" color="primary">
        Compare ({ctx.competitorsList.length})
      </Button>
    </div>
  );
};

export default CompareCompetitorButton;
