/*  eslint-disable  */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";

//admin
export const getFlags = createAsyncThunk(
  "flags/getFlags",
  async (user, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/features/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//user
export const getUserFlags = createAsyncThunk(
  "flags/getUserFlags",
  async (user, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/userfeatures/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const flagDetail = createAsyncThunk(
  "flags/detail",
  async (id, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/features/${id}`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFlag = createAsyncThunk(
  "flags/add",
  async (flag, { rejectWithValue, getState }) => {
    try {
      const res = await axios.post(`${BaseURL}common/features/`, flag);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFlag = createAsyncThunk(
  "flags/deleteFlag",
  async (id, { rejectWithValue, getState }) => {
    try {
      const res = await axios.delete(`${BaseURL}common/features/${id}`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editFlag = createAsyncThunk(
  "flags/edit",
  async (flag, { rejectWithValue, getState }) => {
    try {
      const res = await axios.patch(`${BaseURL}common/features/${flag.id}`, flag);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const flagSlice = createSlice({
  name: "flags",
  initialState: {
    flags: [],
    user_flags: [],
    loading: false,
    flag: {},
    error: initialError,
    fetchedAll: true,
    editflag: false,
    deleteflag: false,
  },

  reducers: {
    removeAlert(state, action) {
      state.error = initialError;
    },
  },

  extraReducers: {
    [getFlags.pending]: (state, action) => {
      state.loading = true;
    },
    [getFlags.fulfilled]: (state, action) => {
      state.loading = false;
      state.flags = action.payload;
    },
    [getFlags.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getUserFlags.pending]: (state, action) => {
      state.loading = true;
      state.fetchedAll = false;
    },
    [getUserFlags.fulfilled]: (state, action) => {
      state.loading = false;
      state.fetchedAll = true;
      state.user_flags = action.payload;
    },
    [getUserFlags.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [deleteFlag.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteFlag.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "Feature Flag Deleted Successfully";
    },
    [deleteFlag.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [editFlag.pending]: (state, action) => {
      state.loading = true;
      state.editflag = false;
    },
    [editFlag.fulfilled]: (state, action) => {
      state.editflag = true;
      state.loading = false;
      state.error.open = true;
      state.error.message = "Feature Flag Updated Successfully";
    },
    [editFlag.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [flagDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [flagDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.flag = action.payload;
    },
    [flagDetail.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [addFlag.pending]: (state, action) => {
      state.loading = true;
      state.flag_added = false;
    },
    [addFlag.fulfilled]: (state, action) => {
      state.loading = false;
      state.flag_added = true;
      state.error.open = true;
      state.error.message = "Feature Flag added Successfully";
    },
    [addFlag.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export const flagActionns = flagSlice.actions;

export default flagSlice.reducer;
