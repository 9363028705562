/*eslint-disable*/
import { Container } from "@mui/system";
import "../../styles/auth/TCmodal.css";
import React from "react";

const RefundPolicy = () => {
  return (
    <div className="TC-container">
      <Container>
        <h2 className="text-lg font-medium">
          Welcome to Footprynt Refund Policy
        </h2>

        <p>1. Please share your queries and concerns on sales@footprynt.in</p>

        <p>
          2. Do note we do not refund 100% of the total value, it is only
          proportionate to the term left on the plan or the proportionate
          services left on the plan.
        </p>

        <p>
          3. Refund shall be processed within 15 days of the request received
          and shall be reversed to the original source of the payment.
        </p>

        <p>
          4. GST or indirect taxes shall be reversed on the government portals
          and only then processed.{" "}
        </p>

        <p>
          5. An administrator representative will connect with you to understand
          the shortcomings, please note it will not be a sales call but an
          internal product improvement call.
        </p>
      </Container>
    </div>
  );
};

export default RefundPolicy;
