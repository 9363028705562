/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
// import { influencerDetailsMock } from "../../__mocks__/influencerReport";

export const getAllInfluencers = createAsyncThunk(
  "getAllInfluencers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios({
        method: "GET",
        baseURL: BaseURL,
        url: "influencer/influencers/",
        params: {
          category: payload.category.join(","),
          // category_ai: payload.category,
          gender: payload.gender,
          page: payload.page,
          influencer_country: payload.country,
          records: payload.record,
          influencer_social_media: payload.socialMedia,
          search: payload.influencer_name,
          follower_count__gte: payload.lower_limit_followers,
          follower_count__lte:
            payload.upper_limit_followers === "10M+"
              ? 1000000000
              : payload.upper_limit_followers,
        },
      });
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSuggestedInfluencers = createAsyncThunk(
  "getAllSuggestedInfluencers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}influencer/influencers/?category=${payload.category}&gender=${payload.gender}&page=${payload.page}&influencer_city=${payload.city}&influencer_state=${payload.state}&influencer_country=${payload.country}&records=${payload.record}&influencer_social_media=${payload.socialMedia}&search=${payload.influencer_name}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Influencer Detail of single influencer
export const SingleInfluencer = createAsyncThunk(
  "influencers/single",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}influencer/influencers/${payload.id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
      // return fulfillWithValue(influencerDetailsMock);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//request new Influencer
export const requestNewInfluencer = createAsyncThunk(
  "influencers/requestNewInfluencer",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}influencer/newinfluencer/`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createGrpInfluencer = createAsyncThunk(
  "influencers/createGrpInfluencer",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}campaign/groups/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateGrpInfluencer = createAsyncThunk(
  "influencers/updateGrpInfluencer11111",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${BaseURL}campaign/groups/${payload.id}`,
        payload
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const DeleteGrpInfluencer = createAsyncThunk(
  "influencers/deleteGrpInfluencer",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${BaseURL}campaign/groups/${payload.id}`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGrpsInfluencer = createAsyncThunk(
  "influencers/getGrpsInfluence12r",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}campaign/usergroupid/?user_id=${payload.user_id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInfluencerinGrp = createAsyncThunk(
  "influencers/addInfluencerinGrp",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}campaign/usergroup/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const DeleteInfluencerfromGrp = createAsyncThunk(
  "influencers/DeleteInfluencerfromGrp",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(`${BaseURL}campaign/usergroup/?id=${id}`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get all the shortlisted influencers from that campaign
export const getSingleCampaignInfluencers = createAsyncThunk(
  "influencers/getSingleCampaignInfluencers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}campaign/usergroup/?user_id=${payload.user}&group_name=${payload.group_name}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSearchSuggestedInfluencers = createAsyncThunk(
  "influencers/getSearchSuggestedInfluencers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}influencer/influencers/?search=${payload.influencer_name}&records=100&page=1&influencer_social_media=${payload?.socialMedia}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const GetInfluencerHistory = createAsyncThunk(
  "influencers/GetInfluencerHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/inf_history/type?=${payload.historyType}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const PostInfluencerHistory = createAsyncThunk(
  "influencers/PostInfluencerHistory",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}common/inf_history/?type=${payload.type}`,
        payload.data
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const CompareInfluencersReport = createAsyncThunk(
  "influencers/CompareInfluencersReport",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}subscription/compare_influencers/?influencers_lists=${payload.influencers_lists}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserInfluencers = createAsyncThunk(
  "influencers/getUserInfluencers",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}influencer/userinfluencers/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const userInfluencersHistory = createAsyncThunk(
  "influencers/userInfluencersHistory",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.get(`${BaseURL}common/inf_history/`);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveUserInfluencersHistory = createAsyncThunk(
  "influencers/saveUserInfluencersHistory",
  async (_payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await axios.post(
        `${BaseURL}common/inf_history/?influencer_id=${_payload.id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return fulfillWithValue(res.data);
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      // ////console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const influencerSlice = createSlice({
  name: "customers",
  initialState: {
    loading: false,
    reqLoader: false,
    campaignLoading: false,
    is_invalid_influencer: false,
    error: initialError,
    influencer: {},
    influencer_d: {},
    influencers: [],
    suggested_influencers: [],
    followers: [],
    influencerStats: [],
    favInfluencers: [],
    addedInf: false,
    removeInf: false,
    count: 23,
    grpCreated: false,
    updatedSingleGrp: false,
    grpDelete: false,
    infAddedToGrp: false,
    deleteInfluencerfromGrp: false,
    getGroups: false,
    getSingleGrp: false,
    grps: [],
    singleGrp: {},
    infsgrp: [],
    singleCampaignInfluencers: [],
    singleCampaignDetails: {},
    dummyCompetitorReport: {},
    searchSuggestedInfluencers: [],
    userInfluencers: [],
    userInfluencersHistoryList: [],
    compare: [],
  },
  reducers: {
    removeAlert(state) {
      state.error = initialError;
    },
  },
  extraReducers: {
    [getAllInfluencers.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getAllInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.influencers = action?.payload?.results?.sort(
        (a, b) => b?.follower_count - a?.follower_count
      );
      state.count = action.payload.count;
    },
    [getAllInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getAllSuggestedInfluencers.pending]: (state, action) => {
      state.loading = true;
      state.error = initialError;
    },
    [getAllSuggestedInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.suggested_influencers = action?.payload?.results;
    },
    [getAllSuggestedInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [SingleInfluencer.pending]: (state, action) => {
      state.loading = true;
      state.is_invalid_influencer = false;
      state.error = initialError;
      state.influencer = {};
    },
    [SingleInfluencer.fulfilled]: (state, action) => {
      state.loading = false;
      state.is_invalid_influencer = false;
      state.influencer = action.payload;
    },
    [SingleInfluencer.rejected]: (state, action) => {
      state.loading = false;
      state.is_invalid_influencer = true;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [requestNewInfluencer.pending]: (state, action) => {
      state.reqLoader = true;
    },
    [requestNewInfluencer.fulfilled]: (state, action) => {
      state.reqLoader = false;
      toast.success(action.payload.msg, {
        ...toastOptions,
        position: "bottom-left",
      });
    },
    [requestNewInfluencer.rejected]: (state, action) => {
      state.reqLoader = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [createGrpInfluencer.pending]: (state, action) => {
      state.loading = true;
      state.grpCreated = false;
    },
    [createGrpInfluencer.fulfilled]: (state, action) => {
      state.loading = false;
      state.grpCreated = true;
      state.error.message = "Campaign Added Successfully";
      state.error.open = true;
    },
    [createGrpInfluencer.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [updateGrpInfluencer.pending]: (state, action) => {
      state.loading = true;
      state.updatedSingleGrp = false;
    },
    [updateGrpInfluencer.fulfilled]: (state, action) => {
      state.updatedSingleGrp = true;
      state.loading = false;
      state.error.message = "Campaign Updated Successfully";
      state.error.open = true;
    },
    [updateGrpInfluencer.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [DeleteGrpInfluencer.pending]: (state, action) => {
      state.loading = true;
      state.grpDelete = false;
    },
    [DeleteGrpInfluencer.fulfilled]: (state, action) => {
      state.loading = false;
      state.grpDelete = true;
      state.error.message = "Campaign Deleted Successfully";
      state.error.open = true;
    },
    [DeleteGrpInfluencer.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getGrpsInfluencer.pending]: (state, action) => {
      state.campaignLoading = true;
      state.getGroups = false;
    },
    [getGrpsInfluencer.fulfilled]: (state, action) => {
      state.campaignLoading = false;
      state.getGroups = true;
      state.grps = action.payload;
    },
    [getGrpsInfluencer.rejected]: (state, action) => {
      state.campaignLoading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [addInfluencerinGrp.pending]: (state, action) => {
      state.loading = true;
      state.infAddedToGrp = false;
    },
    [addInfluencerinGrp.fulfilled]: (state, action) => {
      state.loading = false;
      state.infAddedToGrp = true;
      state.error.open = true;
      state.error.message = " Influencer Added SuccessFully ";
    },
    [addInfluencerinGrp.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [DeleteInfluencerfromGrp.pending]: (state, action) => {
      state.loading = true;
      state.deleteInfluencerfromGrp = false;
    },
    [DeleteInfluencerfromGrp.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteInfluencerfromGrp = true;
      state.error.open = true;
      state.error.message = " Influencer Removed SuccessFully ";
    },
    [DeleteInfluencerfromGrp.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getSingleCampaignInfluencers.pending]: (state, action) => {
      state.loading = true;
    },
    [getSingleCampaignInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleCampaignInfluencers = action.payload;
    },
    [getSingleCampaignInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getSearchSuggestedInfluencers.pending]: (state, action) => {
      state.loading = true;
    },
    [getSearchSuggestedInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchSuggestedInfluencers = action?.payload?.results;
    },
    [getSearchSuggestedInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [getUserInfluencers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUserInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInfluencers = action?.payload;
    },
    [getUserInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [userInfluencersHistory.pending]: (state, action) => {
      state.loading = true;
    },
    [userInfluencersHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInfluencersHistoryList = action?.payload;
    },
    [userInfluencersHistory.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [CompareInfluencersReport.fulfilled]: (state, action) => {
      state.compare = action?.payload;
    },
  },
});

export const influencerActionns = influencerSlice.actions;

export default influencerSlice.reducer;
