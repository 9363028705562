export default function nFormatter(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function convertNumberFormatter(num) {
  if (String(num).includes(",")) {
    return parseInt(num.replace(/,/g, ""));
  }
  if (String(num).toUpperCase().includes("K")) {
    return parseFloat(num.replace("K", "")) * 1000;
  }
  if (String(num).toUpperCase().includes("M")) {
    return parseFloat(num.replace("K", "")) * 1000000;
  }
  return parseInt(num);
}
