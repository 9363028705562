/* eslint-disable */
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editUser, getSingleUsers } from "../../../context/slice/userSlice";
import FormControl from "@mui/material/FormControl";

export const UserProfileDetail = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getUser } = useSelector((store) => store.users);
  const fetchuser = async () => {
    await dispatch(getSingleUsers(id));
  };
  useEffect(() => {
    fetchuser();
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: getUser?.fullName?.toString(),
      email: getUser?.email?.toString(),
      category: getUser?.role?.toString(),
      phone: "7869992103",
      featureFlag: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      fullName: Yup.string().max(255).required("First name is required"),
      phone: Yup.string().length(10).required("Phone No. is required"),
      featureFlag: Yup.boolean().oneOf([true, false]),
      category: Yup.string().oneOf(
        ["influencer", "bussiness", "admin"],
        "Please select the category"
      ),
    }),
    onSubmit: async (values, action) => {
      const data = {
        fullName: values.fullName,
        email: values.email,
        role: values.category,
        is_admin: values.category == "admin" ? true : false,
        is_active: true,
        id: getUser.id.toString(),
      };
      await dispatch(editUser(data));
      navigate("/admin-panel/users");
      action.resetForm();
    },
  });

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardHeader
          sx={{ padding: "20px 32px" }}
          subheader="You can update details of user"
          title="Update User"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <div className="input-block">
                <label className="input-label">Full Name</label>
                <input
                  error={Boolean(
                    formik.touched.fullName && formik.errors.fullName
                  )}
                  fullWidth
                  helperText={formik.touched.fullName && formik.errors.fullName}
                  label="Full Name"
                  margin="normal"
                  name="fullName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className="input-block">
                <label className="input-label">E-mail</label>
                <input
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  type="email"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <div className="input-block">
                    <label className="input-label">Status</label>
                    <select
                      error={Boolean(
                        formik.touched.category && formik.errors.category
                      )}
                      fullWidth
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                      label="Status"
                      margin="normal"
                      name="status"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="status"
                      value={formik.values.category}
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <div className="input-block">
                    <label className="input-label">Role</label>
                    <select
                      error={Boolean(
                        formik.touched.category && formik.errors.category
                      )}
                      fullWidth
                      helperText={
                        formik.touched.category && formik.errors.category
                      }
                      label="Role"
                      margin="normal"
                      name="category"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="category"
                      value={formik.values.category}
                    >
                      <option value={"influencer"}>Influencer</option>
                      <option value={"bussiness"}>Bussiness</option>
                      <option value={"admin"}>Admin</option>
                    </select>
                  </div>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  ml: -1,
                }}
              >
                {/* <Checkbox
                  checked={formik.values.policy}
                  name="featureFlag"
                  onChange={formik.handleChange}
                /> */}
                {/* <Typography color="textSecondary" variant="body2">
                  Do you want the user to show the{" "}
                  <Link href="#" passHref>
                    <MuiLink
                      color="primary"
                      underline="always"
                      variant="subtitle2"
                    >
                      Feature Flags
                    </MuiLink>
                  </Link>
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
          >
            Update details
          </Button>
          {/* <Button style={{ marginLeft: "1rem" }} color="error" variant="contained">
            Delete User
          </Button> */}
        </Box>
      </Card>
    </form>
  );
};
