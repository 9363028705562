/* eslint-disable */
import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";

export const CompareCartContext = createContext({
  items: [],
  addToCart: () => {},
  removeOneFromCart: () => {},
});

export function CompareCartProvider({ children }) {
  const [cartProducts, setCartProducts] = useState([]);

  function addToCart(item) {
    const isExist = cartProducts.find(
      (inf) => inf.id === item.id
    );
    if (isExist) {
      return toast.error("Influencer already been added", toastOptions);
    }
    setCartProducts([...cartProducts, item]);
  }
  
  function removeOneFromCart(id) {
    if (cartProducts.length === 0) {
      return toast.error(
        "Cannot remove Influencers!! Since the cart is empty",
        toastOptions
      );
    }
    setCartProducts(
      cartProducts.filter((product) => product.id !== id)
    );
  }

  const contextValue = {
    items: cartProducts,
    addToCart,
    removeOneFromCart,
  };
  return (
    <CompareCartContext.Provider value={contextValue}>
      {children}
    </CompareCartContext.Provider>
  );
}