import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditCompetitorForm from "./EditCompetitorForm";
/*eslint-disable*/
export default function EditCompetitorModal({ editCompetitor, open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Competitor</DialogTitle>
        <DialogContent style={{ overflowY: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Edit Your Competitor to view the full list of influencers they work with and their
            marketing budgets.
          </DialogContentText>
          <EditCompetitorForm competitor={editCompetitor} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
