/* eslint-disable react/prop-types */
import { Box, Link } from "@mui/material";
import React from "react";
import FootPryntLogo from "../../images/footPryntLogoOrg.png";

const TopSidebar = ({open}) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ py: 3 }}>
        <Link to="/">
          <img loading="lazy" 
            src={FootPryntLogo}
            alt={"footpryntLogo"}
            width={open ? 200 : 70}
            height={open ? 50 : 20}
          />
        </Link>
      </Box>
    </div>
  );
};

export default TopSidebar;
