import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";

export const getAllFavInfluencers = createAsyncThunk(
  "influencers/getAllFavInfluencers",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BaseURL}common/favinfluencer/?user_id=${payload.id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFavouriteInfluencer = createAsyncThunk(
  "influencers/addFavouriteInfluencer",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${BaseURL}common/favinfluencer/`, payload);
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeInfluencerFromFavourite = createAsyncThunk(
  "influencers/removeInfluencerFromFavourite",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `${BaseURL}common/favinfluencer/?id=${id}`
      );
      if (res?.response?.status >= 400) {
        return rejectWithValue(res.response.data);
      }
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
};

const FavouriteInfluencerSlice = createSlice({
  name: "favouriteInfluencers",
  initialState: {
    favInfluencers: [],
    loading: false,
  },
  reducers: {
    removeAlert(state) {
      state.error = initialError;
    },
  },
  extraReducers: {
    [getAllFavInfluencers.pending]: (state) => {
      state.loading = true;
    },
    [getAllFavInfluencers.fulfilled]: (state, action) => {
      state.loading = false;
      state.favInfluencers = action.payload.influencers;
    },
    [getAllFavInfluencers.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );    },
    [addFavouriteInfluencer.pending]: (state) => {
      state.loading = true;
    },
    [addFavouriteInfluencer.fulfilled]: (state) => {
      state.loading = false;
      toast.success("Influencer Added Successfully", toastOptions);
    },
    [addFavouriteInfluencer.rejected]:(state, action)=> {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [removeInfluencerFromFavourite.pending]: (state) => {
      state.loading = true;
    },
    [removeInfluencerFromFavourite.fulfilled]: (state) => {
      state.loading = false;
      toast.success("Influencer removed Successfully", toastOptions);
    },
    [removeInfluencerFromFavourite.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export default FavouriteInfluencerSlice.reducer;
