/* eslint-disable */
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { addFlag } from "../../../context/slice/flagSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export const AddFeatureFlag = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flag_added } = useSelector((store) => store.flag);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: "",
      element: "",
      enable: false,
      role: "business",
    },
    validationSchema: Yup.object({
      page: Yup.string().max(255).required("Page is required"),
      element: Yup.string().max(100).required("element is required"),
      enable: Yup.boolean().oneOf([true, false]),
      role: Yup.string().oneOf(
        ["influencer", "business", "admin"],
        "Please select the category"
      ),
    }),
    onSubmit: async (values, action) => {
      const data = {
        page: values.page,
        element: values.element,
        enabled: values.enable,
        role:
          values.role === "business" ? 3 : values.role === "influencer" ? 2 : 1,
      };
      ////console.log("submited flags", data);
      await dispatch(addFlag(data));
      navigate("/admin-panel/flag");
    },
  });

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography id="demo-simple-select-label">Page Name</Typography>
              <TextField
                error={Boolean(formik.touched.page && formik.errors.page)}
                fullWidth
                helperText={formik.touched.page && formik.errors.page}
                label="Page Name"
                margin="normal"
                name="page"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.page}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography id="demo-simple-select-label">
                Element Name
              </Typography>

              <TextField
                error={Boolean(formik.touched.element && formik.errors.element)}
                fullWidth
                helperText={formik.touched.element && formik.errors.element}
                label="element name"
                margin="normal"
                name="element"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.element}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Enable</InputLabel>
                  <Select
                    error={Boolean(
                      formik.touched.enable && formik.errors.enable
                    )}
                    fullWidth
                    helperText={formik.touched.enable && formik.errors.enable}
                    label="Role"
                    margin="normal"
                    name="enable"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.enable}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    error={Boolean(formik.touched.role && formik.errors.role)}
                    fullWidth
                    helperText={formik.touched.role && formik.errors.role}
                    label="Role"
                    margin="normal"
                    name="role"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="role"
                    value={formik.values.role}
                  >
                    <MenuItem value={"influencer"}>Influencer</MenuItem>
                    <MenuItem value={"business"}>Business</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};
