/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { useDispatch } from "react-redux";
import {
  getHashtagHistory,
  requestHashtag,
} from "../../context/slice/CampaignSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import SendIcon from "@mui/icons-material/Send";

export default function SendEmailModal({ open, setOpen }) {
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const { handleSubmit, handleBlur, handleChange, errors, values, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        subject: "",
        message: "",
      },
      validationSchema: Yup.object({
        subject: Yup.string().required("subject is required"),
        message: Yup.string().required("message is required"),
      }),
      onSubmit: async (values, action) => {
        setLoading((prev) => !prev);
        const data = {
          subject: values.subject,
          message: values.message,
        };
        dispatch(requestHashtag(data))
          .then(unwrapResult)
          .then(() => dispatch(getHashtagHistory()).unwrap())
          .then(() => {
            toast.success("E-mail send successfully!!", toastOptions);
            action.resetForm();
            handleClose();
          })
          .catch((error) => toast.error(
            error.detail || error.msg || "Server error",
            toastOptions
          ))
          .finally(() => setLoading((prev) => !prev));
      },
    });
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ pb: 0.5 }}>Send Email</DialogTitle>
        <DialogContent sx={{ pt: 0.5, pb: 0.5 }}>
          <DialogContentText>
            We&apos;ll send the email to the respected Influencers
          </DialogContentText>
          <TextField
            margin="dense"
            id="subject"
            name="subject"
            label="Subject"
            fullWidth
            variant="outlined"
            placeholder="Enter subject"
            sx={{ mt: 2 }}
            error={Boolean(touched.subject && errors.subject)}
            helperText={touched.subject && errors.subject}
            value={values.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={loading}
          />
          <TextField
            margin="dense"
            id="message"
            name="message"
            label="Message"
            fullWidth
            variant="outlined"
            placeholder="Enter Message"
            sx={{ mt: 2 }}
            multiline
            rows={4}
            error={Boolean(touched.message && errors.message)}
            helperText={touched.message && errors.message}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions sx={{ mr: 2, pt: 0.5, mb: 1, mt: 1.5 }}>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSubmit}
            startIcon={<SendIcon />}
          >
            Send
          </Button>
          <Button
            disabled={loading}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
