/* eslint-disable react/prop-types */
import {
  Card,
  DeltaBar,
  DonutChart,
  Flex,
  List,
  ListItem,
  Text,
  Title,
} from "@tremor/react";
import React from "react";

const colors = ["rose", "indigo", "green"];

export default function TraffivByDevicePieChart({ data }) {
  const TfValues = Object.values(data || {});
  const TfKeys = Object.keys(data || {});
  const TfData = TfKeys.map((item, i) => ({
    device: item,
    value: TfValues[i],
  }));
  return (
    <Card className="max-w-4xl mx-auto">
      <div className="hidden sm:block">
        <Flex className="space-x-4" justifyContent="start" alignItems="center">
          <Title className="truncate">Traffic By Device</Title>
        </Flex>
      </div>
      <DonutChart
        data={TfData}
        category="value"
        index="device"
        variant="pie"
        className="h-52 mt-8"
        colors={["rose", "indigo", "green"]}
        showLabel
        showTooltip
        showAnimation
      />
      <List className="mt-2">
        {TfData.map((item, i) => (
          <ListItem key={item.device}>
            <Text className="truncate">{item.device}</Text>
            <div>
              <Flex justifyContent="end" className="space-x-4">
                <Text color={colors[i]} className="truncate">
                  {item.value}%
                </Text>
                <div className="w-44">
                  <DeltaBar
                    value={item.value}
                    isIncreasePositive={true}
                    tooltip=""
                    showAnimation={true}
                  />
                </div>
              </Flex>
            </div>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
