/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseURL } from "../../services/API_services";

export const getRoles = createAsyncThunk(
  "roles/getRoles",
  async (user, { rejectWithValue, getState }) => {
    try {
      const res = await axios.get(`${BaseURL}common/roles/`);
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "role/deleteRole",
  async (id, { rejectWithValue, getState }) => {
    try {
      const res = await axios.delete(
        `${BaseURL}common/roles/${id}`,
      );
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addRole = createAsyncThunk(
  "role/add",
  async (role, { rejectWithValue, getState }) => {
    try {
      const res = await axios.post(
        `${BaseURL}common/roles/`,
        role,
      );
      return res.data;
    } catch (error) {
      if (!error && !error?.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const initialError = {
  open: false,
  message: "",
  type: "success",
  added: null
};

const roleSlice = createSlice({
  name: "roles",
  initialState: {
    roles: [],
    loading: false,
    role: {},
    error: initialError,
  },

  reducers: {
    removeAlert(state, action) {
      state.error = initialError;
    },
  },

  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.loading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    },
    [getRoles.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [deleteRole.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.error.open = true;
      state.error.message = "Role Deleted Successfully";
    },
    [deleteRole.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
    [addRole.pending]: (state, action) => {
      state.loading = true;
    },
    [addRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.added = '1'
      state.error.open = true;
      state.error.message = "Role Added Successfully";
    },
    [addRole.rejected]: (state, action) => {
      state.loading = false;
      toast.error(
        action.payload.detail || action.payload.msg || "Server error",
        toastOptions
      );
    },
  },
});

export const roleActionns = roleSlice.actions;

export default roleSlice.reducer;