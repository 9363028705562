import { Box } from "@mui/material";
import React from "react";
import PageLoaderGIF from "../../assets/MainLoader.gif";

const PageLoader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#2BBBFB",
        position: "fixed",
        top: "0%",
        left: "0%",
        zIndex: 99999999,
        overflowY: "hidden",
      }}
    >
      <img
        src={PageLoaderGIF}
        alt="PageLoaderGIF"
        style={{ objectFit: "cover" }}
      />
    </Box>
  );
};

export default PageLoader;
