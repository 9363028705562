/* eslint-disable react/prop-types */
import * as React from "react";

export default function AddCoupan({ coupan, setCoupan, applyCoupan, loading }) {
  const handleChange = (event) => {
    setCoupan(event.target.value);
  };

  return (
    <div className="w-full relative my-4">
      <input
        type="search"
        id="influencer-search"
        className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-none"
        placeholder="Enter coupan"
        autoComplete="off"
        autoCorrect="off"
        autoSave="off"
        value={coupan}
        onChange={handleChange}
      />
      <button
        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
        onClick={applyCoupan}
        disabled={loading}
      >
        {loading ? "Applying..." : "Appply"}
      </button>
    </div>
  );
}
