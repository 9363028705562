/*eslint-disable*/
import { createSlice } from "@reduxjs/toolkit";

//this slice is used to manage the state of all Filters globally

const initialValue = {
  filterApiHistory: [],
};

const FilterHistorySlice = createSlice({
  name: "FilterHistorySlice",
  initialState: initialValue,
  reducers: {
    setHistory: (state, action) => {
      if (state.filterApiHistory.find((ele) => JSON.stringify(ele) === JSON.stringify(action.payload))) {
        return;
      } else {
        state.filterApiHistory = [
          ...state.filterApiHistory,
          { api: action.payload, date: Date.now() },
        ];
      }
    },
  },
});

export const FilterHistoryActions = FilterHistorySlice.actions;

export default FilterHistorySlice.reducer;
