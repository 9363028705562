/* eslint-disable react/prop-types */
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Tooltip } from "@mui/material";
import { CompareCompetitorsContext } from "../../context/slice/CompareCompetitorsSlice";
import CompareIcon from "@mui/icons-material/Compare";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CompareCompetitorButton from "../../components/competitor/CompareCompetitorButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const columns = [
  { id: "FullName", label: "FullName", minWidth: 170 },
  { id: "SocialMedia", label: "Social Media", minWidth: 100 },
  {
    id: "Handlename",
    label: "Handle name",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Website",
    label: "Website",
    minWidth: 170,
    align: "center",
  },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 170,
    align: "center",
  },
];

export default function CompetitorTable({
  competitors,
  deleteCompetitorHnadler,
  setEditModalOpen,
  setEditCompetitor,
}) {
  const navigate = useNavigate();
  const ctx = React.useContext(CompareCompetitorsContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const compareCompetitorHandler = (competitor) => {
    ctx.addCompetitorsToCart({
      id: competitor?.id,
      name: competitor?.competitor_full_name,
      handlename: competitor?.competitor_handle_name,
      social_media: competitor?.competitor_social_media?.toLowerCase(),
    });
  };
  const removeFromCompareCartHandler = (id) => {
    ctx.removeOneCompetitorFromCart(id);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", p: 3 }}>
      <CompareCompetitorButton />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {competitors?.map((competitor, i) => {
              const isCompared = ctx.competitorsList.find(
                (c) => c.id == competitor.id
              );
              return (
                <TableRow
                  key={competitor?.competitor_full_name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  hover
                  onClick={() =>
                    navigate(`/competitor-analysis/${competitor?.id}`)
                  }
                >
                  <TableCell component="th" scope="row">
                    {competitor?.competitor_full_name}
                  </TableCell>
                  <TableCell>{competitor?.competitor_social_media}</TableCell>
                  <TableCell
                    style={{ color: "blue", cursor: "pointer" }}
                    align="center"
                  >
                    <a
                      onClick={(e) => e.stopPropagation()}
                      style={{ textDecoration: "none" }}
                      href={
                        competitor?.competitor_social_media === "twitter"
                          ? `https://www.twitter.com/${competitor.competitor_handle_name}/`
                          : competitor?.competitor_social_media === "youtube"
                          ? `https://www.youtube.com/@${competitor.competitor_handle_name}`
                          : `https://www.instagram.com/${competitor.competitor_handle_name}/`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      @{competitor?.competitor_handle_name}
                    </a>
                  </TableCell>
                  <TableCell
                    style={{ color: "blue", cursor: "pointer" }}
                    align="center"
                  >
                    <a
                      onClick={(e) => e.stopPropagation()}
                      style={{ textDecoration: "none" }}
                      href={
                        String(competitor?.competitor_website).startsWith(
                          "https://"
                        )
                          ? competitor?.competitor_website
                          : "https://" + competitor?.competitor_website
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {competitor?.competitor_website}
                    </a>
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Tooltip title="View">
                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() =>
                          navigate(`/competitor-analysis/${competitor?.id}`)
                        }
                      >
                        <VisibilityIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        sx={{ ml: 1 }}
                        onClick={() => {
                          setEditModalOpen((prev) => !prev);
                          setEditCompetitor(competitor);
                        }}
                      >
                        <ModeEditOutlineIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    {window.location.pathname === "/competitor-analysis" && (
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() =>
                            deleteCompetitorHnadler(competitor?.id)
                          }
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!isCompared ? (
                      <Tooltip title="Compare">
                        <IconButton
                          sx={{ ml: 1.5 }}
                          onClick={() => compareCompetitorHandler(competitor)}
                          id={"cmp" + i}
                        >
                          <CompareIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Remove from Compared List">
                        <IconButton
                          sx={{ ml: 1.5 }}
                          onClick={() =>
                            removeFromCompareCartHandler(competitor.id)
                          }
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={competitors.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
