/* eslint-disable react/prop-types */
import React from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
export const CampaignWidget = ({ title, value, icon, color }) => {
  return (
    <Card sx={{ height: "90%", border: ".3px solid rgba(223, 219, 219, .6)" }}>
      <CardContent>
        <Grid
          //   container
          //   spacing={3}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Grid item sx={{ flex: ".75" }}>
            <Typography color="textPrimary" gutterBottom variant="overline">
              {title}
            </Typography>
            <Typography color="textSecondary" variant="h6" fontSize={"14px"}>
              {value}
            </Typography>
          </Grid>
          <Grid
            sx={{ flex: ".25", display: "flex", justifyContent: "flex-end" }}
          >
            <Avatar
              sx={{
                backgroundColor: color,
                height: 40,
                width: 40,
              }}
            >
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
