/* eslint-disable */
import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { LatestUsers } from "../../components/admin/users/LatestUsers";

const AdminPanel = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <LatestUsers />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default AdminPanel