export const reelsData = {
  campaign: "#catchabreak",
  social_media: "instagram",
  total_posts: 2,
  likes: 429390,
  views: 5909012,
  comments: 2263,
  most_liked_post: {
    post_id: "CsAtJMFPKtr",
    post_link: "https://www.instagram.com/p/CsAtJMFPKtr",
    Partnership_with: "https://www.instagram.com/anushkasen0408",
    caption:
      "When I can\u2019t string things together, it\u2019s time to #CatchABreak with KitKat.\n\nHere\u2019s how you can #CatchABreak:\n1. Create your reel catching a break\n2. Tag @kitkatindia to get featured\n3. Don\u2019t forget to use the hashtag #CatchABreak\n\n#KitKat #CatchABreak #ad",
    comments: 2167,
    likes: 361174,
    created_at: "2023-05-09",
    paid_partnership: true,
    views: 4518965,
  },
  most_commented_post: {
    post_id: "CsAtJMFPKtr",
    post_link: "https://www.instagram.com/p/CsAtJMFPKtr",
    Partnership_with: "https://www.instagram.com/anushkasen0408",
    caption:
      "When I can\u2019t string things together, it\u2019s time to #CatchABreak with KitKat.\n\nHere\u2019s how you can #CatchABreak:\n1. Create your reel catching a break\n2. Tag @kitkatindia to get featured\n3. Don\u2019t forget to use the hashtag #CatchABreak\n\n#KitKat #CatchABreak #ad",
    comments: 2167,
    likes: 361044,
    created_at: "2023-05-09 11:17:44",
    paid_partnership: true,
    views: 4512052,
  },
  paid_partnership: null,
  organic_posts: null,
  likes_analytics: [
    { date: "2023-08-20", likes: 361044 },
    { date: "2023-08-21", likes: 429342 },
    { date: "2023-08-22", likes: 429390 },
  ],
  comments_analytics: [
    { date: "2023-08-20", comments: 2167 },
    { date: "2023-08-21", comments: 2263 },
    { date: "2023-08-22", comments: 2263 },
  ],
  views_analytics: [
    { date: "2023-08-20", views: 4512052 },
    { date: "2023-08-21", views: 5905966 },
    { date: "2023-08-22", views: 5909012 },
  ],
  posts_analytics: [],
  max_post_datetime: null,
  posts: [
    "https://www.instagram.com/reel/CsAtJMFPKtr",
    "https://www.instagram.com/reel/CrtE4hfgY0x",
  ],
  most_viewed_post: {
    post_id: "CsAtJMFPKtr",
    post_link: "https://www.instagram.com/p/CsAtJMFPKtr",
    Partnership_with: "https://www.instagram.com/anushkasen0408",
    caption:
      "When I can\u2019t string things together, it\u2019s time to #CatchABreak with KitKat.\n\nHere\u2019s how you can #CatchABreak:\n1. Create your reel catching a break\n2. Tag @kitkatindia to get featured\n3. Don\u2019t forget to use the hashtag #CatchABreak\n\n#KitKat #CatchABreak #ad",
    comments: 2167,
    likes: 361174,
    created_at: "2023-05-09",
    paid_partnership: true,
    views: 4518965,
  },
  hashtags: [
    { hashtags: "#catchabreak", count: 15 },
    { hashtags: "#catchabreak:", count: 5 },
    { hashtags: "#kitkat", count: 5 },
    { hashtags: "#ad", count: 5 },
  ],
};
