/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileForm from "../../components/settings/ProfileForm";
import SocialMediaHandlename from "../../components/settings/SocialMediaHandlename";
// import CompanyDetails from "../../components/settings/CompanyDetails";
import * as Yup from "yup";
import { useFormik } from "formik";
import { apihandleActions } from "../../context/slice/ApiCalls";
import { editUser } from "../../context/slice/userSlice";
import CompanyDetails from "../../components/settings/CompanyDetails";
import { toast } from "react-toastify";
import LocationDetails from "../../components/settings/LocationDetails";

const profileSchema = Yup.object({
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email().required("email is required"),
  phoneNo: Yup.string("^d{10}").length(10).required("Phone No. is required"),
  youtube_handle_name: Yup.string(),
  twitter_handle_name: Yup.string(),
  instagram_handle_name: Yup.string(),
  companyName: Yup.string(),
  companyLink: Yup.string(),
  companyEmail: Yup.string().email().notRequired(),
  address_1: Yup.string(),
  city: Yup.string(),
  state: Yup.string().required(),
  country: Yup.string(),
  zipcode: Yup.string("^d{6}"),
});

const UserProfileSettings = ({ setTab }) => {
  const { user } = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const initialProfileValues = {
    fullName: user.user.fullName ? user.user.fullName : "",
    email: user.user.email ? user.user.email : "",
    phoneNo: user.user.phno ? user.user.phno : "",
    instagram_handle_name: user.user.instagram_handle_name
      ? user.user.instagram_handle_name
      : "",
    twitter_handle_name: user.user.twitter_handle_name
      ? user.user.twitter_handle_name
      : "",
    youtube_handle_name: user.user.youtube_handle_name
      ? user.user.youtube_handle_name
      : "",
    companyName: user.user.company ? user.user.company : "",
    companyLink: user.user.company_link ? user.user.company_link : "",
    companyEmail: user.user.company_email ? user.user.company_email : "",
    address_1: user.user.address_1 ? user.user.address_1 : "",
    city: user.user.city ? user.user.city : "",
    state: user.user.state ? user.user.state : "",
    country: user.user.country ? user.user.country : "",
    zipcode: user.user.zipcode ? String(user.user.zipcode) : "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialProfileValues,
      validationSchema: profileSchema,
      onSubmit: async (values) => {
        const userData = {
          id: user.user.id,
          fullName: values.fullName.trim().length > 0 ? values.fullName : null,
          email: values.email.trim().length > 0 ? values.email : null,
          phno: values.phoneNo ? values.phoneNo : null,
          dp: user?.user?.dp,
          company:
            values.companyName.trim().length > 0 ? values.companyName : null,
          company_link:
            values.companyLink.trim().length > 0 ? values.companyLink : null,
          company_email:
            values.companyEmail.trim().length > 0 ? values.companyEmail : null,
          instagram_handle_name:
            values.instagram_handle_name.trim().length > 0
              ? values.instagram_handle_name
              : null,
          twitter_handle_name:
            values.twitter_handle_name.trim().length > 0
              ? values.twitter_handle_name
              : null,
          youtube_handle_name:
            values.youtube_handle_name.trim().length > 0
              ? values.youtube_handle_name
              : null,
          address_1:
            values.address_1.trim().length > 0 ? values.address_1 : null,
          city: values.city.trim().length > 0 ? values.city : null,
          state: values.state.trim().length > 0 ? values.state : null,
          country: values.country.trim().length > 0 ? values.country : null,
          zipcode: values.zipcode.trim().length > 0 ? +values.zipcode : null,
        };
        if (valid) {
          dispatch(editUser({ ...userData }));
          dispatch(apihandleActions.startProfilecompleteCall());
        } else {
          return toast.error("Invalid URL");
        }
      },
    });

  const isDisable =
    (user?.user?.fullName === values.fullName ||
      values.fullName.length === 0) &&
    (user?.user?.email === values.email || values.email.length === 0) &&
    (user?.user?.phno === values.phoneNo || values.phoneNo.length === 0) &&
    (user?.user?.instagram_handle_name === values.instagram_handle_name ||
      values.instagram_handle_name.length === 0) &&
    (user?.user?.twitter_handle_name === values.twitter_handle_name ||
      values.twitter_handle_name.length === 0) &&
    (user?.user?.youtube_handle_name === values.youtube_handle_name ||
      values.youtube_handle_name.length === 0) &&
    (user?.user?.company_email === values.companyEmail ||
      values.companyEmail.length === 0) &&
    (user?.user?.company === values.companyName ||
      values.companyName.length === 0) &&
    (user?.user?.company_link === values.companyLink ||
      values.companyLink.length === 0) &&
    (user?.user?.address_1 === values.address_1 ||
      values.address_1.length === 0) &&
    (user?.user?.city === values.city || values.city.length === 0) &&
    (user?.user?.state === values.state || values.state.length === 0) &&
    (user?.user?.country === values.country || values.country.length === 0) &&
    (user?.user?.zipcode == values.zipcode || values.zipcode.length === 0);

  return (
    <>
      <ProfileForm
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <SocialMediaHandlename
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <CompanyDetails
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={valid}
        setValid={setValid}
      />
      <LocationDetails
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        valid={valid}
        setValid={setValid}
      />
      <Box
        style={{
          marginTop: ".5rem",
          width: "98%",
          marginBottom: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Button disabled={true} variant="outlined">
          Prev
        </Button>
        <Button
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
          disabled={isDisable}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
        <Button
          onClick={() => setTab((prev) => prev + 1)}
          style={{ marginLeft: "1rem" }}
          variant="outlined"
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default UserProfileSettings;
