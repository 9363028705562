/* eslint-disable react/prop-types */
import {
  Card,
  DeltaBar,
  DonutChart,
  Flex,
  List,
  ListItem,
  Text,
  Title,
} from "@tremor/react";
import React from "react";

const colors = ["#3F51B5", "#fcbec8"];

export default function AgeWiseBreakDown({ data }) {
  const TfData = [
    {
      gender: "15-25",
      value: data?.["15-25"],
    },
    {
      gender: "25-35",
      value: data?.["25-35"],
    },
    {
      gender: "35-50",
      value: data?.["35-50"],
    },
    {
      gender: "50+",
      value: data?.["50+"],
    },
  ];
  return (
    <Card className="max-w-4xl mx-auto">
      <div className="hidden sm:block">
        <Flex className="space-x-4" justifyContent="start" alignItems="center">
          <Title className="truncate">Age-wise BreakDown</Title>
        </Flex>
      </div>
      <DonutChart
        data={TfData}
        category="value"
        index="gender"
        variant="pie"
        className="h-52 mt-8"
        colors={["violet", "rose", "cyan", "amber"]}
        showTooltip
        showAnimation
        showLabel
      />
      <List className="mt-2">
        {TfData.map((item, i) => (
          <ListItem key={item.gender}>
            <Text className="truncate">{item.gender}</Text>
            <div>
              <Flex justifyContent="end" className="space-x-4">
                <Text color={colors[i]} className="truncate">
                  {item.value}%
                </Text>
                <div className="w-44">
                  <DeltaBar
                    value={item.value * 100}
                    isIncreasePositive={true}
                    tooltip=""
                    showAnimation={true}
                  />
                </div>
              </Flex>
            </div>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
