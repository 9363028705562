/* eslint-disable */
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SeverityPill } from "../../../components/home/severity-pill";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteUser, getAllUsers } from "../../../context/slice/userSlice";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../components/shared/Loader"

const GetAllUsers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, loading } = useSelector((store) => store.users);
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const deleteUserHandler = async (id) => {
    await dispatch(deleteUser(id));
    await dispatch(getAllUsers());
  };

  return (
    <Card {...props}>
      <CardHeader
        title="All User Accounts"
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }} style={{ margin: "1rem 1.2rem" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <Loader />}
              {!loading && users.map((user) => (
                <TableRow
                  // onClick={() => navigate(`/admin-panel/users/${user.id}`)}
                  // hover
                  key={user.id}
                // style={{ cursor: "pointer" }}
                >

                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <SeverityPill color={user?.is_active ? "success" : "error"}>
                      {user?.is_active ? "Active" : "Inactive"}
                    </SeverityPill>
                  </TableCell>
                  <TableCell>
                    <EditIcon
                      onClick={() => navigate(`/admin-panel/users/${user.id}`)}
                      style={{ color: "#42855B" }}
                    />
                    <DeleteIcon
                      onClick={() => deleteUserHandler(user.id)}
                      style={{ color: "#FF6464", marginLeft: "5px" }}
                    />
                    {/* <RemoveRedEyeIcon onClick={() => navigate(`/admin-panel/users/${user.id}`)} style={{ color: "blue", marginLeft: "5px"}}/> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default GetAllUsers;