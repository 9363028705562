/* eslint-disable react/prop-types */
import React from "react";
import { TextField } from "@mui/material";
// import UserImage from "../../images/auth/UserImage.jpeg";

const ProfileForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <form
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gridGap: ".85rem",
        width: "100%",
        padding: "1rem",
      }}
    >
      <TextField
        autoComplete="off"
        name="fullName"
        id="fullName"
        label="FullName"
        placeholder="Enter Your FullName"
        value={values.fullName}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        helperText={touched.fullName && errors.fullName}
        error={errors.fullName && touched.fullName}
        style={{ marginTop: "1rem" }}
        required
       
      />
      <TextField
        autoComplete="off"
        name="email"
        id="email"
        label="E-mail"
        placeholder="Enter Your Email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        helperText={touched.email && errors.email}
        error={errors.email && touched.email}
        style={{ marginTop: "1rem" }}
        required
     
      />
      <TextField
        autoComplete="off"
        name="phoneNo"
        id="phoneNo"
        label="Phone No."
        placeholder="Enter Your Phone No."
        value={values.phoneNo}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        helperText={touched.phoneNo && errors.phoneNo}
        error={errors.phoneNo && touched.phoneNo}
        style={{ marginTop: "1rem" }}
        required
      />
    </form>
  );
};

export default ProfileForm;
