import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DeleteGrpInfluencer } from "../../context/slice/influencerSlice";
import AddCampaignDrawer from "./AddCampaign/AddCampaignDrawer";
import { apihandleActions } from "../../context/slice/ApiCalls";
import { getInitials } from "../../utils/getInitials";
import nFormatter from "../../utils/nFormatter";
/*eslint-disable*/
const columns = [
  { id: "name", label: "Campaign Name", minWidth: 200 },
  { id: "social_media", label: "Social Media", align: "left", minWidth: 100 },
  { id: "budget", label: "Budget", align: "left", minWidth: 100 },
  {
    id: "size",
    label: "Size\u00a0(Influencers)",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "actions",
    label: "Action",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

export default function CampaignTable({
  campaigns,
  searchProduct,
  numberOfInfluencersFromCampaign,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteCampaignHandler = (id) => {
    dispatch(DeleteGrpInfluencer({ id }));
    dispatch(apihandleActions.startCampaignsCall());
  };

  return (
    <Paper sx={{ width: "95%", overflow: "hidden", margin: ".5rem 2rem" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns
              ?.filter((grp) =>
                grp.campaign_identify_name
                  .toLowerCase()
                  .includes(searchProduct.toLowerCase())
              )
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((campaign) => {
                return (
                  <TableRow
                    key={campaign?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{ cursor: "pointer" }}
                    hover
                    onClick={() => navigate(`/campaign/${campaign?.id}`)}
                  >
                    <TableCell
                      style={{ display: "flex", alignItems: "center" }}
                      component="th"
                      scope="row"
                    >
                      <Avatar variant="circle" style={{ marginRight: "8px", width: "30px", height: "30px" }}>
                        {getInitials(campaign?.campaign_identify_name?.substring(1))}
                      </Avatar>
                      {campaign?.campaign_identify_name}
                    </TableCell>
                    <TableCell align="left">
                      {campaign?.campaign_social_media}
                    </TableCell>
                    <TableCell align="left">
                      {nFormatter(campaign?.campaign_upper_budget)}
                    </TableCell>
                    <TableCell align="center">
                      {
                        numberOfInfluencersFromCampaign?.filter(
                          (inf) => inf?.group === campaign.id
                        )?.length
                      }{" "}
                      Influencers
                    </TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                      align="center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Tooltip title="View">
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => navigate(`/campaign/${campaign.id}`)}
                        >
                          <VisibilityIcon color="info" />
                        </IconButton>
                      </Tooltip>
                      <AddCampaignDrawer
                        id={campaign.id}
                        campaign={campaign}
                        page={"Edit"}
                      />
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => deleteCampaignHandler(campaign.id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={campaigns?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
