import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
/* eslint-disable */
export default function SettingsTab({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        mt: 1,
        width: "100%",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="scrollable force auto tabs example"
      >
        <Tab label="Profile" />
        <Tab label="Notification" />
        <Tab label="Favourite Influencers" />
        <Tab label="Subscription" />
        <Tab label="Accessed Reports" />
        <Tab label="Transactions" />
      </Tabs>
    </Box>
  );
}
