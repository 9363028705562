/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Card,
  DeltaBar,
  DonutChart,
  Flex,
  List,
  ListItem,
  Text,
  Title,
  Bold,
} from "@tremor/react";
import React from "react";

const colors = ["#3F51B5", "#fcbec8"];

export default function GenderFollowersPieChart({ data }) {
  const TfData = [
    {
      gender: "Male",
      value: +data?.split("/")?.[0] | 0,
    },
    {
      gender: "Female",
      value: +data?.split("/")?.[1] | 0,
    },
  ];
  return (
    <Card className="mx-auto">
      <div className="hidden sm:block">
        <Flex className="space-x-4" justifyContent="start" alignItems="center">
          <Title className="truncate">Gender-wise Followers</Title>
        </Flex>
      </div>
      <DonutChart
        data={TfData}
        category="value"
        index="gender"
        variant="donut"
        className="h-52 mt-8"
        colors={["indigo", "pink"]}
        showAnimation
        showLabel
      />
      <List className="mt-6">
        {TfData.map((item, i) => (
          <ListItem key={item.gender}>
            <Text className="truncate">{item.gender}</Text>
            <div>
              <Flex justifyContent="end" className="space-x-4">
                <Text color={colors[i]} className="truncate">
                  {item.value}%
                </Text>
                <div className="w-44">
                  <DeltaBar
                    value={item.value}
                    isIncreasePositive={true}
                    tooltip=""
                    showAnimation={true}
                  />
                </div>
              </Flex>
            </div>
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
