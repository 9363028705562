/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import { Button, CardHeader, TextField, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

const flex = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
};

const LocationDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div style={{ ...flex, width: "100%" }}>
      <CardHeader
        title="Your Location"
        sx={{ m: 0, p: 0, mt: 0.5, ml: 3, width: "95%" }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
          gridGap: ".85rem",
          width: "100%",
          padding: "1rem",
          paddingTop: "0%",
        }}
      >
        <TextField
          autoComplete="off"
          name="address_1"
          id="address_1"
          label="Address"
          placeholder="Enter Your Address"
          value={values.address_1}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.address_1 && errors.address_1}
          error={errors.address_1 && touched.address_1}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="city"
          id="city"
          label="City"
          placeholder="Enter Your City"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.city && errors.city}
          error={errors.city && touched.city}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="state"
          id="state"
          label="State"
          placeholder="Enter Your State"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.state && errors.state}
          error={errors.state && touched.state}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="country"
          id="country"
          label="country"
          placeholder="Enter Your country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          select
          helperText={touched.country && errors.country}
          error={errors.country && touched.country}
          style={{ marginTop: "1rem" }}
        >
          <MenuItem value={"IN"}>India</MenuItem>
          <MenuItem value={"AE"}>UAE</MenuItem>
          <MenuItem value={"US"}>USA</MenuItem>
          <MenuItem value={"GB"}>United Kingdom</MenuItem>
        </TextField>
        <TextField
          autoComplete="off"
          name="zipcode"
          id="zipcode"
          label="zipcode"
          placeholder="Enter Your Zip Code"
          value={values.zipcode}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.zipcode && errors.zipcode}
          error={errors.zipcode && touched.zipcode}
          style={{ marginTop: "1rem" }}
        />
      </div>
    </div>
  );
};

export default LocationDetails;
