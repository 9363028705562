/* eslint-disable react/prop-types */
import { Box, CardHeader, Typography } from "@mui/material";
import React, { Fragment } from "react";
import InfluencersPosts from "./CampaignDetailPage/InfluencersPosts";
import { convertNumberFormatter } from "../../utils/nFormatter";
import timeAgo from "../../utils/DateConverter";
import NoData1 from "../../images/NoData/NoCompare.avif";
import NoData2 from "../../images/NoData/NoChartsData.avif";

const PostsContainer = ({ campaign, socialMedia, title }) => {
  return (
    <Fragment>
      <CardHeader title={title} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(300px, 1fr))",
          width: "100%",
          margin: "0 auto",
          gridGap: ".85rem",
        }}
      >
        {campaign?.length > 0 &&
          campaign?.map((post, i) => {
            return (
              <InfluencersPosts
                key={i}
                image={post?.profile_link}
                user={post?.fullname || post?.handle_name}
                likes={convertNumberFormatter(post.likes)}
                socialMedia={socialMedia}
                comments={convertNumberFormatter(post.comments)}
                views={convertNumberFormatter(post.views)}
                createdAt={timeAgo(post?.created_at)}
                link={post?.post_link}
                style={{ minHeight: "150px" }}
                score={post?.score}
                
              />
            );
          })}
      </div>
      {(campaign?.length === 0 || !campaign) && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <img
            loading="lazy"
            src={title === "Organic Posts" ? NoData1 : NoData2}
            alt="NoData"
            style={{ width: "400px" }}
          />
          <Typography style={{ fontSize: "1.02rem", fontWeight: "600" }}>
            {title} data is not avaliable
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default PostsContainer;
