import { MenuItem, MenuList, Popover } from '@mui/material';
/*eslint-disable*/
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../context/slice/userSlice';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function viewMyProfileHandler() {
    navigate("/user-profile")
    onClose?.();
  }

  const handleSignOut = async () => {
    onClose?.();
    await dispatch(userLogout())
    navigate("/login")
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '200px' }
      }}
      style={{position: "absolute", top: "8%", left: "-2%"}}
    >
      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px'
            },
            padding: '12px 16px'
          }
        }}
      >
        <MenuItem onClick={viewMyProfileHandler}>
          My Profile
        </MenuItem>
      </MenuList>
      <MenuList
        disablePadding
      >
        <MenuItem onClick={handleSignOut}>
          Sign out
        </MenuItem>
      </MenuList>
    </Popover>
  );
};
