/*eslint-disable*/
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import influencerSlice from "../slice/influencerSlice";
import userSlice from "../slice/userSlice";
import FlagReducer from "../slice/flagSlice";
import roleSlice from "../slice/roleSlice";
import FeaturedContent from "../slice/FeaturedContent";
import { axiosMiddleware } from "../../middleware/axiosMiddleware";
import ApiCalls from "../slice/ApiCalls";
import CompetitorSlice from "../slice/CompetitorSlice";
import CampaignSlice from "../slice/CampaignSlice";
import InfluencerState from "../slice/InfluencerState";
import HistorySlice from "../slice/HistorySlice";
import FavouriteInfluencerSlice from "../slice/FavouriteInfluencerSlice";
import FilterHistorySlice from "../slice/FilterHistorySlice";
import DashboardSlice from "../slice/DashboardSlice";
import SubscriptionSlice from "../slice/SubscriptionSlice";

const reducers = combineReducers({
  users: userSlice,
  dashboard: DashboardSlice,
  influencers: influencerSlice,
  flag: FlagReducer,
  role: roleSlice,
  featuredContent: FeaturedContent,
  apihandle: ApiCalls,
  competitor: CompetitorSlice,
  campaign: CampaignSlice,
  influencersState: InfluencerState,
  history: HistorySlice,
  favouriteInfluencer: FavouriteInfluencerSlice,
  FilterHistory: FilterHistorySlice,
  subscription: SubscriptionSlice
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware)=>{
    return getDefaultMiddleware().concat(axiosMiddleware)
  }
});

export default store;
