/* eslint-disable react/prop-types */
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TermsAndCondition from "../../components/shared/TermsAndCondition";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85vw",
  bgcolor: "background.paper",
  border: ".2px solid #fff",
  boxShadow: 24,
  p: 4,
  height: "85vh",
  borderRadius: "10px",
  overflowY: "scroll",
};

export default function TermsAndConditionPolicyModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <TermsAndCondition />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
