/*eslint-disable*/
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
const AddCampaign = React.lazy(() =>
  import("../../../pages/campaign/AddCampaign")
);
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Tooltip from "@mui/material/Tooltip";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function AddCampaignDrawer({
  page,
  id,
  campaign,
  btn,
  setAddCampaignError,
  addCampaignError,
}) {
  const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const { user } = useSelector((store) => store.users);
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box sx={{ width: showAdvancedOptions ? "90vw" : 350 }} role="presentation">
      <React.Suspense
        fallback={
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        {open && (
          <AddCampaign
            id={id}
            page={page}
            campaign={campaign}
            onClose={toggleDrawer(anchor, false)}
            showAdvancedOptions={showAdvancedOptions}
            setShowAdvancedOptions={setShowAdvancedOptions}
            addCampaignError={addCampaignError}
            setAddCampaignError={setAddCampaignError}
          />
        )}
      </React.Suspense>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {page === "Add" && btn && (
            <Button
              onClick={
                user?.user?.role === "business"
                  ? toggleDrawer(anchor, true)
                  : () =>
                      toast.error(
                        "Please Login as a Business User to add campaigns",
                        {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      )
              }
              color="primary"
              variant="contained"
            >
              Create Campaign
            </Button>
          )}
          {page === "Add" && btn === false && (
            <p
              onClick={toggleDrawer(anchor, true)}
              style={{
                cursor: "pointer",
                fontSize: ".85rem",
                marginRight: "0.75rem",
                color: "#2716e2",
                fontWeight: "600",
              }}
            >
              Create Campaign
            </p>
          )}
          {page === "Edit" && (
            <Tooltip title="Edit">
              <IconButton onClick={toggleDrawer(anchor, true)} sx={{ ml: 1 }}>
                <ModeEditOutlineIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
