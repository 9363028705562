/* eslint-disable react/prop-types */
import React from "react";
import ReactWordcloud from "react-wordcloud";
import { Resizable } from "re-resizable";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { Box } from "@mui/system";
import { CardHeader, Divider, Typography } from "@mui/material";
import "d3-transition";
import NoHashtags from "../../../images/social_media/noHashtags.avif";

const HastagsWordCloud = ({ hashtags, handlename }) => {
  const resizeStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [15, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 1,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 0,
  };

  return (
    <Box style={{ border: ".3px solid rgba(223, 219, 219, .6)" }}>
      <CardHeader title="HashTags" />
      <Divider />
      {hashtags?.length > 0 && (
        <div style={{ margin: "1.75rem 0" }}>
          <Resizable
            defaultSize={{
              width: "100%",
              height: "100%",
            }}
            style={resizeStyle}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <ReactWordcloud
                words={hashtags?.map((hashtag) => {
                  return {
                    text: hashtag?.hashtags,
                    value: hashtag?.count,
                  };
                })}
                options={options}
              />
            </div>
          </Resizable>
        </div>
      )}
      {(hashtags?.length === 0 || !hashtags) && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            paddingBottom: "3.75rem",
          }}
        >
          <img loading="lazy"  src={NoHashtags} alt="NoHashtags" style={{ width: "400px" }} />
          <Typography style={{ fontSize: "1.02rem", fontWeight: "600" }}>
            No Generic Hashtags Used By{" "}
            <span style={{ color: "blue" }}>@{handlename}</span>
          </Typography>
        </Box>
      )}
      <Divider />
    </Box>
  );
};

export default HastagsWordCloud;
