import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCompetitorsForm from "./AddCompetitorForm";
/*eslint-disable*/
export default function AddCompetitorModal({ open, setOpen, setAddCompetitorError }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Competitor</DialogTitle>
        <DialogContent style={{ overflowY: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            Get the full list of influencers they work with and spy on their
            marketing budgets.
          </DialogContentText>
          <AddCompetitorsForm handleClose={handleClose} 
        setAddCompetitorError={setAddCompetitorError}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}
