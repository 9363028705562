/*eslint-disable*/
import * as React from "react";
import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function MultipleSelect({ value, name, id, onChange }) {
  return (
    <Box sx={{ pr: 14, display: "flex", alignItems: "center" }}>
      Email
      <Checkbox
        checked={value}
        name={name}
        id={id}
        onChange={onChange}
        aria-label="Email"
      />
    </Box>
  );
}