/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip, Badge, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import useMediaQuery from "@mui/material/useMediaQuery";

import { unwrapResult } from "@reduxjs/toolkit";
import FavInfluencersContainer from "./FavInfluencersContainer";
import {
  getAllFavInfluencers,
  removeInfluencerFromFavourite,
} from "../../../context/slice/FavouriteInfluencerSlice";
import { Button as TremorButton } from "@tremor/react";

import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

export default function FavInfDrawer({ showButton = false }) {
  const dispatch = useDispatch();
  const mediaQuery1 = useMediaQuery("(min-width:980px)");
  const mediaQuery2 = useMediaQuery("(min-width:560px)");
  const { user } = useSelector((store) => store.users);
  const { favInfluencers } = useSelector((store) => store.favouriteInfluencer);
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    dispatch(getAllFavInfluencers({ id: user?.user?.id }));
  }, [dispatch]);

  const removeFavInfHandler = (inf) => {
    const favItem = favInfluencers?.find(
      (favinf) => favinf?.influencer_handle_name === inf?.influencer_handle_name
    );
    dispatch(removeInfluencerFromFavourite(favItem.fav_influencer_id))
      .then(unwrapResult)
      .then(() => {
        dispatch(getAllFavInfluencers({ id: user?.user?.id }));
      });
  };

  const width = mediaQuery1 ? "50vw" : mediaQuery2 ? "70vw" : "85vw";

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : width }}
      role="presentation"
      onKeyDown={() => toggleDrawer(anchor, false)}
    >
        <Typography variant="h2" sx={{ mt: "1.4rem", ml: "1.4rem", fontSize: "1.5rem" }}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {user?.user?.fullName}'s Favourite Influencers
        </Typography>
      <FavInfluencersContainer
        toggleDrawer={toggleDrawer}
        removeFavInfHandler={removeFavInfHandler}
        favInfluencers={favInfluencers}
        mediaQuery={mediaQuery2}
      />
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {!showButton && (
            <Tooltip title="Favorite Influencer">
              <IconButton
                id="fav-influencers"
                onClick={() => toggleDrawer(anchor, true)}
                sx={{ ml: 1 }}
              >
                <Badge
                  color="secondary"
                  badgeContent={
                    favInfluencers?.length > 9 ? "9+" : favInfluencers?.length
                  }
                >
                  <FavoriteBorderIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          {showButton && (
            <TremorButton
              size="sm"
              variant="light"
              icon={ArrowNarrowRightIcon}
              iconPosition="right"
              className="mt-4"
              onClick={() => toggleDrawer("right", true)}
            >
              View Favourite Influencers
            </TremorButton>
          )}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
