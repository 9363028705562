/* eslint-disable react/prop-types */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toastOptions";
import { useDispatch, useSelector } from "react-redux";
import { RequestSubscriptionPlan } from "../../context/slice/SubscriptionSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export default function RequestAnotherModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const { sending_mail } = useSelector((store) => store.subscription);

  const { handleSubmit, handleBlur, handleChange, errors, values, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        Plan: "",
        Description: "",
      },
      validationSchema: Yup.object({
        Plan: Yup.string().required("Select Plan is required"),
        Description: Yup.string(),
      }),
      onSubmit: async (values) => {
        const data = {
          plan: values.Plan,
          description: values.Description,
        };
        dispatch(RequestSubscriptionPlan(data))
          .then(unwrapResult)
          .then(() => setOpen(false))
          .then(() =>
            toast.success("We'll contact with you shortly!!", toastOptions)
          );
      },
    });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Request Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Want to change plan? Fill this form and we'll call you after some
            time!!
          </DialogContentText>
          <TextField
            style={{ marginTop: "1rem" }}
            id="Plan"
            name="Plan"
            label="Plan"
            select
            helperText={touched.Plan && errors.Plan}
            error={Boolean(errors.Plan) && touched.Plan}
            value={values.Plan}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            disabled={sending_mail}
          >
            <MenuItem value={"Monthly"}>Monthly</MenuItem>
            <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
            <MenuItem value={"Annually"}>Annually</MenuItem>
            <MenuItem value={"Customize your plan"}>
              Customize your plan
            </MenuItem>
          </TextField>
          <TextField
            style={{ marginTop: ".5rem" }}
            margin="dense"
            id="Description"
            name="Description"
            label="Description"
            placeholder="Description (Optional)"
            type="text"
            fullWidth
            variant="outlined"
            error={Boolean(touched.Description && errors.Description)}
            helperText={touched.Description && errors.Description}
            value={values.Description}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={sending_mail}
          />
        </DialogContent>
        <DialogActions>
          {sending_mail ? (
            <Button disabled={true} variant="contained" onClick={handleSubmit}>
              Sending...
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          )}
          <Button
            disabled={sending_mail}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}