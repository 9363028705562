/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Button, Container } from "@mui/material";
import React, { useState } from "react";
import Starter from "../../images/subscription/Starter.avif";
import Premium from "../../images/subscription/Premium.avif";
import Advance from "../../images/subscription/Advance.avif";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubscriptionCard = ({
  price,
  months,
  plan,
  influencers,
  campaign,
  competitors,
  competitorCampaigns,
  plan_id,
  showAlert,
  setValue,
  setSelectedPlan,
}) => {
  const { user } = useSelector((store) => store.users);
  const { subscription_history } = useSelector((store) => store.subscription);

  const isPrevPlan =
    subscription_history?.[subscription_history?.length - 1]?.Description ===
    plan
      ? true
      : false;

  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        border: ".1px solid gray",
        padding: "1rem",
        borderRadius: "1rem",
      }}
    >
      <div
        style={{
          margin: "1rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={
            plan === "Monthly"
              ? Starter
              : plan === "Quarterly"
              ? Advance
              : Premium
          }
          style={{ width: "200px", height: "175px" }}
          alt={plan}
        />
        <h2 style={{ fontSize: "1.55rem" }}>{plan}</h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          margin: "1rem 0",
        }}
      >
        <span
          style={{
            fontSize: "1.85rem",
            fontWeight: "600",
            marginRight: "10px",
          }}
        >
          {user.user.country === "AE"
            ? price + " AED"
            : user.user.country === "IN"
            ? "₹ " + price
            : user.user.country === "US" ? price + " USD" : user.user.country === "GB" ? "£ "+price : price}
        </span>
      </div>
      <ul>
        <li style={{ margin: ".55rem", fontWeight: "500", fontSize: ".95rem" }}>
          <span style={{ fontWeight: "600", fontSize: "1rem" }}>
            {influencers}{" "}
          </span>
          Influencers
        </li>
        <li style={{ margin: ".55rem", fontWeight: "500", fontSize: ".95rem" }}>
          <span style={{ fontWeight: "600", fontSize: "1rem" }}>{months} </span>
          Days Duration
        </li>
        <li style={{ margin: ".55rem", fontWeight: "500", fontSize: ".95rem" }}>
          Get Data for
          <span style={{ fontWeight: "600", fontSize: "1rem" }}>
            {" "}
            {competitors}{" "}
          </span>
          Competitors
        </li>
        <li style={{ margin: ".55rem", fontWeight: "500", fontSize: ".95rem" }}>
          Create
          <span style={{ fontWeight: "600", fontSize: "1rem" }}>
            {" "}
            {campaign}{" "}
          </span>
          {campaign === 1 ? "Campaign" : "Campaigns"}
        </li>
      </ul>
      <div style={{ marginTop: "1rem" }}>
        <Button
          onClick={() => {
            setValue("2");
            setSelectedPlan(plan_id);
          }}
          variant="contained"
        >
          {!isPrevPlan ? "Buy Plan" : "Renew Plan"}
        </Button>
      </div>
    </Container>
  );
};

export default SubscriptionCard;

{
  /* <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 ">
<h5 className="mb-4 text-xl font-medium text-gray-500 ">Standard plan</h5>
<div className="flex items-baseline text-gray-900 ">
    <span className="text-3xl font-semibold">$</span>
    <span className="text-5xl font-extrabold tracking-tight">49</span>
    <span className="ml-1 text-xl font-normal text-gray-500 ">/month</span>
</div>
<ul role="list" className="space-y-5 my-7">
    <li className="flex space-x-3 items-center">
        <svg className="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">2 team members</span>
    </li>
    <li className="flex space-x-3">
        <svg className="flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">20GB Cloud storage</span>
    </li>
    <li className="flex space-x-3">
        <svg className="flex-shrink-0 w-4 h-4 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500 ">Integration help</span>
    </li>
    <li className="flex space-x-3 line-through decoration-gray-500">
        <svg className="flex-shrink-0 w-4 h-4 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500">Sketch Files</span>
    </li>
    <li className="flex space-x-3 line-through decoration-gray-500">
        <svg className="flex-shrink-0 w-4 h-4 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500">API Access</span>
    </li>
    <li className="flex space-x-3 line-through decoration-gray-500">
        <svg className="flex-shrink-0 w-4 h-4 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500">Complete documentation</span>
    </li>
    <li className="flex space-x-3 line-through decoration-gray-500">
        <svg className="flex-shrink-0 w-4 h-4 text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        <span className="text-base font-normal leading-tight text-gray-500">24×7 phone & email support</span>
    </li>
</ul>
<button type="button" className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Choose plan</button>
</div> */
}
