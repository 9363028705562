import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/* eslint-disable */
import FormControl from "@mui/material/FormControl";
import { editFlag, flagDetail } from "../../../context/slice/flagSlice";

export const EditFlag = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flag } = useSelector((store) => store.flag);
  const fetchFeatureFlag = async () => {
    await dispatch(flagDetail(id));
  };

  useEffect(() => {
    fetchFeatureFlag();
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page: flag?.page?.toString(),
      element: flag?.element?.toString(),
      enable: flag?.enabled,
      role:
        flag?.role === 3
          ? "business"
          : flag?.role === 2
          ? "influencer"
          : "admin",
    },
    validationSchema: Yup.object({
      page: Yup.string().max(255).required("Page is required"),
      element: Yup.string().max(100).required("element is required"),

      enable: Yup.boolean().oneOf([true, false]),
      role: Yup.string().oneOf(
        ["influencer", "bussiness", "admin"],
        "Please select the category"
      ),
    }),
    onSubmit: async (values, action) => {
      const data = {
        page_name: values.page,
        element_name: values.element,
        enabled: values.enable,
        role_id:
          values.role === "business" ? 3 : values.role === "influencer" ? 2 : 1,
        id,
      };
      await dispatch(editFlag(data));
      navigate("/admin-panel/flag");
    },
  });

  return (
    <form>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <div className="input-block">
                <label className="input-label">Page Name</label>
                <input
                  error={Boolean(formik.touched.page && formik.errors.page)}
                  fullWidth
                  helperText={formik.touched.page && formik.errors.page}
                  label="Page Name"
                  margin="normal"
                  name="page"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.page}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className="input-block">
                <label className="input-label">Element Name</label>
                <input
                  error={Boolean(
                    formik.touched.element && formik.errors.element
                  )}
                  fullWidth
                  helperText={formik.touched.element && formik.errors.element}
                  label="element name"
                  margin="normal"
                  name="element"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.element}
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <div className="input-block">
                    <label className="input-label">Enable</label>
                    <select
                      error={Boolean(
                        formik.touched.enable && formik.errors.enable
                      )}
                      fullWidth
                      helperText={formik.touched.enable && formik.errors.enable}
                      label="Role"
                      margin="normal"
                      name="enable"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.enable}
                    >
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </select>
                  </div>
                </FormControl>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <div className="input-block">
                    <label className="input-label">Role</label>
                    <select
                      error={Boolean(formik.touched.role && formik.errors.role)}
                      fullWidth
                      helperText={formik.touched.role && formik.errors.role}
                      label="Role"
                      margin="normal"
                      name="role"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="role"
                      value={formik.values.role}
                    >
                      <option value={"influencer"}>Influencer</option>
                      <option value={"bussiness"}>Bussiness</option>
                      <option value={"admin"}>Admin</option>
                    </select>
                  </div>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            onClick={formik.submitForm}
            color="primary"
            variant="contained"
          >
            Update details
          </Button>
        </Box>
      </Card>
    </form>
  );
};
