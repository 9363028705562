import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./context/store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CompareCartProvider } from "./context/slice/CompareInfluencerSlice";
import { CompareCompetitorsProvider } from "./context/slice/CompareCompetitorsSlice";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <CompareCartProvider>
        <CompareCompetitorsProvider>
          <App />
        </CompareCompetitorsProvider>
      </CompareCartProvider>
    </Provider>
  </BrowserRouter>
);
